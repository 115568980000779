import ChangePassword from "src/modals/ChangePassword";
import CreditMessage from "src/modals/CreditMessage";
import UpdateNotes from "src/modals/UpdateNotes";

const Modals = () => {
  return (
    <div>
      <ChangePassword />
      <CreditMessage />
      <UpdateNotes />
    </div>
  );
};

export default Modals;
