import PropTypes from "prop-types";
import React from "react";

const Button = (props) => {
  if (props.color === "red") {
    return (
      <button
        type="button"
        {...props}
        className={`px-3 py-2 rounded flex items-center bg-red-600 text-white disabled:bg-red-300 hover:bg-red-800 focus:outline-none whitespace-nowrap
        ${props.size === "sm" && "text-sm"}`}
      />
    );
  } else if (props.color === "gray") {
    return (
      <button
        type="button"
        {...props}
        className={`px-3 py-2 rounded flex items-center bg-gray-600 text-white disabled:bg-gray-300 hover:bg-gray-800 focus:outline-none whitespace-nowrap
        ${props.size === "sm" && "text-sm"}`}
      />
    );
  } else {
    return (
      <button
        type="button"
        {...props}
        className={`px-3 py-2 rounded flex items-center bg-blue-600 text-white disabled:bg-blue-300 hover:bg-blue-800 focus:outline-none whitespace-nowrap
        ${props.size === "sm" && "text-sm"}`}
      />
    );
  }
};

Button.propTypes = {
  color: PropTypes.oneOf(["red", "blue", "gray"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  "data-cy": PropTypes.string,
};

Button.defaultProps = {
  color: "blue",
  size: "md",
  "data-cy": "",
};

export default React.memo(Button);
