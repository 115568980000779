import dagre from "dagre";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ELK from "elkjs";
import { isNode } from "react-flow-renderer";
dayjs.extend(utc);

/**
 * 배열 형식의 shape를 문자열 형식으로 변환합니다.
 * @param {*} array
 * @param {*} nullToken
 * @returns
 */
export const getModelShapeString = (array, nullToken) => {
  let string = "<";
  for (let i = 0; i < array.length; i++) {
    if (i > 0) {
      string += " x ";
    }
    if (Number.isInteger(array[i])) {
      string += array[i];
    } else if (array[i] === null || array[i] === undefined) {
      string += nullToken;
    } else if (Array.isArray(array[i])) {
      string += getModelShapeString(array[i], nullToken);
    }
  }
  string += ">";
  return string;
};
/**
 * dagre 라이브러리를 사용하여 정점과 간선 정보를 이용하여 각각의 정점과 간선의 위치를 추론합니다.
 * @param {*} elements
 * @param {*} direction
 * @returns
 */
export const getDagreLayoutedElements = (
  model,
  availableLayers,
  direction = "TB"
) => {
  const nodes = model.nodes.map((node) =>
    makeNodeForView(node, availableLayers)
  );
  const edges = model.edges.map((edge) => makeEdgeForView(edge));
  const elements = [...nodes, ...edges];
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeWidth = 350;
  const nodeHeight = 100;
  const isHorizontal =
    direction === "LR" ? true : direction === "TB" ? false : false;
  dagreGraph.setGraph({ rankdir: direction });
  elements.forEach((element) => {
    if (isNode(element)) {
      dagreGraph.setNode(element.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(element.source, element.target);
    }
  });
  dagre.layout(dagreGraph);
  const layoutedElements = elements.map((element) => {
    if (isNode(element)) {
      const nodeWithPosition = dagreGraph.node(element.id);
      element.targetPosition = isHorizontal ? "left" : "top";
      element.sourcePosition = isHorizontal ? "right" : "bottom";
      element.position = {
        // x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2 + Math.random() / 1000,
      };
    }
    return element;
  });
  return layoutedElements;
};
/**
 * 객체를 배열로 변환합니다.
 * @param {*} object
 * @returns
 */
export const objectToArray = (object) => {
  const array = [];
  for (const key in object) {
    array.push([key, object[key]]);
  }
  return array;
};
/**
 * 스네이크케이스 문자열을 대문자로 시작하는 문자열로 변환합니다.
 * @param {*} string
 * @returns
 */
export const snakeCaseToTitleCase = (string) => {
  return string
    .split("_")
    .map((word) =>
      word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : ""
    )
    .join(" ")
    .trim();
};
/**
 * 객체를 폼으로 변환합니다.
 * @param {*} object
 * @returns
 */
export const objectToFormData = (object) => {
  const formData = new FormData();
  Object.entries(object).forEach(([key, value]) => {
    if (value === "") formData.append(key, "");
    else formData.append(key, value);
  });
  return formData;
};
/**
 * 범위 또는 특정 인덱스를 뜻하는 문자열을 배열로 변환합니다.
 * @param {*} s
 * @returns
 */
export const getIndexListFromString = (s) => {
  const indexStrings = s.split(",");
  const indexSet = new Set();
  for (const indexString of indexStrings) {
    if (indexString.indexOf("-") > -1) {
      // *-* 형태
      const [num1, num2] = indexString.split("-");
      const start = Math.min(num1, num2);
      const finish = Math.max(num1, num2);
      for (let i = start; i <= finish; i++) {
        indexSet.add(i);
      }
    } else if (indexString % 1 === 0) {
      // * 형태
      indexSet.add(Number(indexString));
    }
  }
  const indexList = [...indexSet];
  const indexSort = indexList.sort((a, b) => a - b);
  return indexSort;
};
/**
 * 파일명으로부터 확장자를 얻습니다.
 * @param {*} fileName
 * @returns
 */
export const getFileNameExtension = (fileName) => {
  if (fileName.indexOf(".") > 0) {
    return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
  } else {
    return "";
  }
};
/**
 * 파일 변경 이벤트로부터 파일을 얻습니다.
 * @param {React.BaseSyntheticEvent<HTMLInputElement>} e
 * @returns
 */
export const getFileFromEvent = (e) => {
  if (e.type === "drop") {
    return e.dataTransfer.files[0];
  } else if (e.type === "change") {
    return e.target.files[0];
  }
};
/**
 * 입력된 utc string을 local string으로 변환합니다.
 */
export const getLocalTimeString = (utcString) => {
  return dayjs.utc(utcString).local().format("YYYY-MM-DD HH:mm:ss");
};
/**
 * api에서 얻은 device 값을 라벨을 위한 표현으로 변경합니다.
 */
export const getDeviceLabel = (deviceName) => {
  const deviceName2DeviceLabel = {
    RaspberryPi4B: "Raspberry Pi 4B",
    RaspberryPi3BPlus: "Raspberry Pi 3B+",
    RaspberryPi2B: "Raspberry Pi 2B",
    "RaspberryPi-ZeroW": "Raspberry Pi Zero W",
    "Jetson-Nano": "NVIDIA Jetson Nano",
    "Jetson-Nx": "NVIDIA Xavier NX",
    "Jetson-Tx2": "NVIDIA TX2 NX",
    "Jetson-Xavier": "NVIDIA AGX Xavier",
  };
  return deviceName2DeviceLabel[deviceName];
};
/**
 * api에서 얻은 framework 값을 라벨을 위한 표현으로 변경합니다.
 */
export const getFrameworkLabel = (framework) => {
  const frameworkName2FrameworkLabel = {
    onnx: "ONNX (.onnx)",
    tensorflow_keras: "TensorFlow-Keras (.h5, .zip)",
    tensorflow_lite: "TensorFlow Lite (.tflite)",
    tensorrt: "TensorRT (.trt)",
    openvino: "OpenVINO (.zip)",
    pytorch: "PyTorch GraphModule (.pt)",
  };
  return frameworkName2FrameworkLabel[framework];
};
/**
 * api에서 얻은 compression method 값을 라벨을 위한 표현으로 변경합니다.
 */
export const getCompressionMethodLabel = (compressionMethod) => {
  const method2methodName = {
    FD_TK: "Tucker Decomposition",
    FD_SVD: "Singular Value Decomposition",
    FD_CP: "CP Decomposition",
    PR_L2: "L2 Norm Pruning",
    PR_GM: "GM Pruning",
    PR_NN: "Nuclear Norm Pruning",
    PR_ID: "Pruning By Index",
  };
  return method2methodName[compressionMethod];
};

/**
 * task에 가능한 metric unit 목록을 얻습니다.
 */
export const getMetricUnitsFromTask = (task) => {
  const task2metricUnits = {
    image_classification: ["Top-1 Accuracy", "Top-5 Accuracy"],
    object_detection: ["mAP[0.5]", "mAP[0.50:0.95]"],
    image_segmentation: ["PA(Pixel Accuracy)", "MPA(Mean Pixel Accuracy)"],
    semantic_segmentation: ["mIoU", "PA (Pixel Accuracy)"],
    instance_segmentation: [
      "AP[IoU=.5:.05:.95]",
      "AP[IoU=0.50]",
      "AP[IoU=0.75]",
    ],
    panoptic_segmentation: [
      "PQ (Panoptic quality)",
      "PQ_st (PQ averaged over stuff classes)",
      "PQ_th (PQ averaged over thing classes)",
    ],
  };
  const metrics = task2metricUnits[task];
  if (metrics) {
    return metrics;
  } else {
    return [];
  }
};

/**
 * api로 받은 노드 데이터를 화면 구성에 필요한 형태의 노드 데이터로 변환합니다.
 */
export const makeNodeForView = (node, availableLayers) => {
  const DEFAULT_WIDTH = 500;
  const DEFAULT_HEIGHT = 100;
  return {
    ...node,
    id: `N${node.id}`,
    isAvailable: availableLayers.find(
      (layer) => layer.name === node.values.name
    ),
    width: node.__rf?.width ?? DEFAULT_WIDTH,
    height: node.__rf?.height ?? DEFAULT_HEIGHT,
  };
};

/**
 * api로 받은 엣지 데이터를 화면 구성에 필요한 형태의 엣지 데이터로 변환합니다.
 */
export const makeEdgeForView = (edge) => {
  return {
    ...edge,
    id: `N${edge.from}-N${edge.to}`,
    source: `N${edge.from}`,
    target: `N${edge.to}`,
  };
};

const elk = new ELK();
/**
 * elk 라이브러리를 사용하여 정점과 간선 정보를 이용하여 각각의 정점과 간선의 위치를 추론합니다.
 * @param {*} model
 * @param {*} availableLayers
 * @returns
 */
export const getLayoutedElements = async (model, availableLayers) => {
  const nodes = model.nodes.map((node) =>
    makeNodeForView(node, availableLayers)
  );
  const edges = model.edges.map((edge) => makeEdgeForView(edge));
  const layoutedGraph = await elk.layout({
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.direction": "DOWN",
      "elk.alignment": "CENTER",
      "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
      "elk.contentAlignment": "H_CENTER",
      "elk.spacing.nodeNode": "50",
      "elk.layered.spacing.nodeNodeBetweenLayers": "50",
    },
    children: nodes,
    edges: edges,
  });
  const layoutedElements = [];
  const layoutedNodes = layoutedGraph.children;
  nodes.forEach((node) => {
    const layoutedNode = layoutedNodes.find(
      (layoutedNode) => layoutedNode.id === node.id
    );
    layoutedElements.push({
      ...node,
      position: {
        x: layoutedNode.x - layoutedNode.width / 2 + Math.random() / 1000,
        y: layoutedNode.y - layoutedNode.height / 2,
      },
    });
  });
  edges.forEach((edge) => layoutedElements.push(edge));
  return layoutedElements;
};
