import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LabelTitle from "src/components/LabelTitle";
import Paper from "src/components/Paper";
import PhraseTitle from "src/components/PhraseTitle";
import Spinner from "src/components/Spinner";
import { getCompression, getModel } from "src/library/apis";
import {
  getCompressionMethodLabel,
  getDeviceLabel,
  getFrameworkLabel,
  getLocalTimeString,
} from "src/library/utils";
import NetworkGraphTable from "src/components/NetworkGraphTable";
import UpdateModal from "./UpdateModal";

function Magnification({ children }) {
  return <div className="text-green-700 text-xs font-bold">(x{children})</div>;
}
export default function ModelInfo() {
  const { modelId } = useParams();
  useEffect(() => {
    setCompressedModelId(modelId);
  }, [modelId]);
  const [layers, setLayers] = useState([]);
  const [compressedModelId, setCompressedModelId] = useState();
  const [originalModelId, setOriginalModelId] = useState();
  const [compressionId, setCompressionId] = useState();
  const GetCompressedModel = useQuery(
    ["compressedModel", compressedModelId],
    () => getModel({ modelId: compressedModelId }),
    {
      enabled: !!compressedModelId,
      onSuccess: ({ data }) => {
        setOriginalModelId(data.original_model_id);
        setCompressionId(data.original_compression_id);
      },
      onError: () => {},
    }
  );
  const GetOriginalModel = useQuery(
    ["originalModel", originalModelId],
    () => getModel({ modelId: originalModelId }),
    {
      enabled: !!originalModelId,
      onSuccess: () => {},
      onError: () => {},
    }
  );
  const GetCompression = useQuery(
    ["compression", compressionId],
    () =>
      getCompression({
        modelId: originalModelId,
        compressionId: compressionId,
      }),
    {
      enabled: !!compressionId,
      onSuccess: ({ data }) => {
        const layers = data.available_layers.map((available_layer) => {
          return {
            ...available_layer,
            text: available_layer.values.join(", "),
          };
        });
        setLayers(layers);
      },
      onError: () => {},
    }
  );
  const originalModelTargetDeviceLatency = useMemo(() => {
    if (GetOriginalModel.isSuccess) {
      const targetDevice = GetOriginalModel?.data.data.devices.find(
        (device) => device.name === GetOriginalModel.data.data.target_device
      );
      if (targetDevice) {
        return targetDevice.total_latency;
      }
    }
    return 0;
  }, [GetOriginalModel]);
  const compressedModelTargetDeviceLatency = useMemo(() => {
    if (GetCompressedModel.isSuccess) {
      const targetDevice = GetCompressedModel?.data.data.devices.find(
        (device) => device.name === GetCompressedModel.data.data.target_device
      );
      if (targetDevice) {
        return targetDevice.total_latency;
      }
    }
    return 0;
  }, [GetCompressedModel]);
  const [open, setOpen] = useState(false);
  const handleClickEdit = () => {
    setOpen(true);
  };

  if (
    !(
      GetOriginalModel.isSuccess &&
      GetCompressedModel.isSuccess &&
      GetCompression.isSuccess
    )
  ) {
    return (
      <div className="w-full h-[500px] flex items-center justify-center">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className="py-4">
        <Paper>
          <div className="p-4 flex flex-col gap-12">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <div className="font-['Gilroy'] text-blue-800 text-[28px] font-bold">
                  {GetCompressedModel.data.data.model_name}
                </div>
                {GetCompressedModel.data.data.origin_from !== "npms" && (
                  <IconButton onClick={handleClickEdit} disableRipple>
                    <EditIcon />
                  </IconButton>
                )}
                {open && (
                  <UpdateModal
                    open={open}
                    setOpen={setOpen}
                    GetOriginalModel={GetOriginalModel}
                    GetCompressedModel={GetCompressedModel}
                  />
                )}
              </div>
              <div>{GetCompressedModel.data.data.description}</div>
            </div>

            <div className="flex flex-col gap-4">
              <PhraseTitle>Compression info</PhraseTitle>
              <div className="grid grid-cols-4">
                <div>
                  <LabelTitle>Framework</LabelTitle>
                  <div>
                    {getFrameworkLabel(GetCompressedModel.data.data.framework)}
                  </div>
                </div>
                <div>
                  <LabelTitle>Created</LabelTitle>
                  <div>
                    {getLocalTimeString(
                      GetCompressedModel.data.data.created_time
                    )}
                  </div>
                </div>
                <div>
                  <LabelTitle>Compression method</LabelTitle>
                  <div>
                    {getCompressionMethodLabel(
                      GetCompression.data.data.compression_method
                    )}
                  </div>
                </div>
                <div>
                  <LabelTitle>Latency profiling target</LabelTitle>
                  <div>
                    {getDeviceLabel(GetCompressedModel.data.data.target_device)}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <PhraseTitle>Model performance</PhraseTitle>
              <table>
                <thead>
                  <tr className="text-left">
                    <th></th>
                    <th>
                      <div>Evaluation Metric</div>
                      {GetOriginalModel.data.data.metric.metric_unit && (
                        <div>
                          ({GetOriginalModel.data.data.metric.metric_unit})
                        </div>
                      )}
                    </th>
                    <th>Latency(ms)</th>
                    <th>Size(MB)</th>
                    <th>FLOPs(M)</th>
                    <th>
                      Trainable
                      <br />
                      Parameters(M)
                    </th>
                    <th>
                      Non Trainable
                      <br />
                      Parameters(M)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="text-left">Original</th>
                    <td>
                      {!!GetOriginalModel.data.data.metric.metric_value &&
                        GetOriginalModel.data.data.metric.metric_value}
                    </td>
                    <td>
                      {originalModelTargetDeviceLatency > 0
                        ? `${originalModelTargetDeviceLatency} ms`
                        : originalModelTargetDeviceLatency === 0
                        ? "Measuring..."
                        : originalModelTargetDeviceLatency === -1
                        ? "Error"
                        : ""}
                    </td>
                    <td>{GetOriginalModel.data.data.spec.model_size}</td>
                    <td>
                      {GetOriginalModel.data.data.spec.flops.toLocaleString()}
                    </td>
                    <td>
                      {GetOriginalModel.data.data.spec.trainable_parameters.toLocaleString()}
                    </td>
                    <td>
                      {GetOriginalModel.data.data.spec.non_trainable_parameters.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left">Compressed</th>
                    <td>
                      {!!GetCompressedModel.data.data.metric.metric_value &&
                        GetCompressedModel.data.data.metric.metric_value}
                    </td>
                    <td>
                      {compressedModelTargetDeviceLatency > 0
                        ? `${compressedModelTargetDeviceLatency} ms`
                        : compressedModelTargetDeviceLatency === 0
                        ? "Measuring..."
                        : compressedModelTargetDeviceLatency === -1
                        ? "Error"
                        : ""}
                    </td>
                    <td>
                      {GetCompressedModel.data.data.spec.model_size}
                      <Magnification>
                        {(
                          GetOriginalModel.data.data.spec.model_size /
                          GetCompressedModel.data.data.spec.model_size
                        ).toFixed(1)}
                      </Magnification>
                    </td>
                    <td>
                      {GetCompressedModel.data.data.spec.flops.toLocaleString()}
                      <Magnification>
                        {(
                          GetOriginalModel.data.data.spec.flops /
                          GetCompressedModel.data.data.spec.flops
                        ).toFixed(1)}
                      </Magnification>
                    </td>
                    <td>
                      {GetCompressedModel.data.data.spec.trainable_parameters.toLocaleString()}
                      <Magnification>
                        {(
                          GetOriginalModel.data.data.spec.trainable_parameters /
                          GetCompressedModel.data.data.spec.trainable_parameters
                        ).toFixed(1)}
                      </Magnification>
                    </td>
                    <td>
                      {GetCompressedModel.data.data.spec.non_trainable_parameters.toLocaleString()}
                      {!Number.isNaN(
                        GetOriginalModel.data.data.spec
                          .non_trainable_parameters /
                          GetCompressedModel.data.data.spec
                            .non_trainable_parameters
                      ) && (
                        <Magnification>
                          {(
                            GetOriginalModel.data.data.spec
                              .non_trainable_parameters /
                            GetCompressedModel.data.data.spec
                              .non_trainable_parameters
                          ).toFixed(1)}
                        </Magnification>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex flex-col gap-4">
              <PhraseTitle>Compression detail</PhraseTitle>
              {GetCompression.data.data.compression_method.startsWith(
                "PR_"
              ) && (
                <div>
                  <LabelTitle>Policy</LabelTitle>
                  <div>{GetCompression.data.data.options.policy}</div>
                </div>
              )}
              <div>
                <LabelTitle>Details</LabelTitle>
                <div className="border w-fit">
                  <NetworkGraphTable
                    model={GetOriginalModel.data.data}
                    compression={GetCompression.data.data}
                    layers={layers}
                    // setLayers={setLayers}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
