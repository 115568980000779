import { memo } from "react";

const Title = ({ title, subtitle }) => {
  return (
    <div className="pb-8 font-['Gilroy']">
      <div className="pb-2 text-5xl text-white">{title}</div>
      <div className="text-white">{subtitle}</div>
    </div>
  );
};

export default memo(Title);
