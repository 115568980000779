import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import useStore from "src/stores";
import Button from "../components/Button";

// 더 이상 사용되지 않음
const UpdateNotes = () => {
  const { modal } = useStore();
  const doNotShowTime = localStorage.getItem("doNotShowTime20210110");
  const [doNotShow, setDoNotShow] = useState(false);
  const handleClose = () => {
    modal.setUpdateModalShow(false);
    if (doNotShow) {
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + 24 * 7);
      localStorage.setItem("doNotShowTime20210110", expires);
    }
  };
  // useEffect(() => {
  //   if (!doNotShowTime || doNotShowTime < new Date()) {
  //     modal.setUpdateModalShow(true);
  //   }
  // }, []);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={modal.updateNotesModalShow}
      onClose={handleClose}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <div>Update Notes(May 27, 2022)</div>
          <button onClick={handleClose} className="focus:outline-none">
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        {/* <div className="text-xl font-bold">Update</div> */}
        <div className="text-lg font-bold text-blue-800">
          New model type is now available!
        </div>
        <ul className="list-inside list-disc">
          <li>Multiple-output model of PyTorch framework.</li>
        </ul>
        <br />
        <div className="text-lg font-bold text-blue-800">
          New feature has been added!
        </div>
        <ul className="list-inside list-disc">
          <li>
            You can check the latency of each PyTorch framework model layer.
            <li>
              <span className="text-red-600">Not</span> available for 200MB+
              models.
            </li>
          </li>
        </ul>
        <br />

        {/* <div className="text-xl font-bold">Notice</div>
        <div className="text-lg font-bold text-blue-800">
          Extending Beta Period for NetsPresso Model Compressor!
        </div>
        <ul className="list-inside list-disc">
          <li>
            There have been many requests from our users to extend the beta
            period for NetsPresso Model Compressor. Although we had planned to
            temporarily close the service after the beta period to make
            improvements, we decided to keep the service open until June, 2022.
            At the same time, we will continuously update our service to provide
            better service and experience to you. We also plan to provide as
            much support as we can to our beta testers. Please do not hesitate
            to use your credits.
          </li>
        </ul>
        <br /> */}
        <a
          href="https://docs.netspresso.ai/docs/may-27-2022"
          target="_blank"
          rel="noreferrer"
        >
          Go To Update Notes
        </a>
      </DialogContent>
      <DialogActions>
        <div className="flex items-center gap-2">
          <label className="m-0 flex items-center justify-center">
            <input
              type="checkbox"
              id="update-notes-do-not-show"
              checked={doNotShow}
              onChange={(e) => setDoNotShow(e.target.checked)}
            />
            &nbsp;Don't show it this week
          </label>
          <Button onClick={handleClose}>Confirm</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default observer(UpdateNotes);
