import { observable } from "mobx";
import { getUser } from "src/library/apis";
import { removeTokens } from "src/library/token";

const user = observable({
  id: null,
  name: null,
  email: null,
  firstName: null,
  lastName: null,
  company: null,
  isActive: null,
  isAdmin: null,
  createdTime: null,
  lastLoginTime: null,
  currentTime: null,
  credit: null,
  marketingAgreement: null,
  privacyPolicyAgreement: null,
  setUserInfo(userInfo) {
    this.id = userInfo.user_id;
    this.name = userInfo.username;
    this.email = userInfo.email;
    this.firstName = userInfo.first_name;
    this.lastName = userInfo.last_name;
    this.company = userInfo.company;
    this.isActive = userInfo.is_active;
    this.isAdmin = userInfo.is_admin;
    this.createdTime = userInfo.created_time;
    this.lastLoginTime = userInfo.last_login_time;
    this.currentTime = userInfo.current_time;
    this.credit = userInfo.credit;
    this.marketingAgreement = userInfo.marketing_agreement;
    this.privacyPolicyAgreement = userInfo.privacy_policy_agreement;
  },
  resetUserInfo() {
    this.id = null;
    this.name = null;
    this.email = null;
    this.firstName = null;
    this.lastName = null;
    this.company = null;
    this.isActive = null;
    this.isAdmin = null;
    this.createdTime = null;
    this.lastLoginTime = null;
    this.currentTime = null;
    this.credit = null;
    this.modelShowLimit = null;
    this.marketingAgreement = null;
    this.privacyPolicyAgreement = null;
    removeTokens();
  },
  async signout() {
    this.resetUserInfo();
  },
  async getUserInfo() {
    const response = await getUser();
    return response;
  },
});

export { user };
