import React, { useState } from "react";
import Login from "./Login";
import NewPassword from "./NewPassword";
import RightSide from "./RightSide";
import SignUp from "./SignUp";

const LoginPage = () => {
  const [site, setSite] = useState("login");
  const changeSite = (site) => {
    setSite(site);
  };
  return (
    <div className="w-screen h-screen flex">
      <div className="h-full min-w-[500px] max-w-[500px] p-16 bg-gradient-to-r from-[#002979] to-[#006dc4] flex flex-col justify-between overflow-auto">
        <div />
        {site === "login" ? (
          <Login changeSite={changeSite} />
        ) : site === "signup" ? (
          <SignUp changeSite={changeSite} />
        ) : site === "newpassword" ? (
          <NewPassword changeSite={changeSite} />
        ) : null}
        <div className="mt-4 flex items-center justify-center">
          <a
            href="mailto:netspresso@nota.ai"
            className="text-white hover:underline"
          >
            netspresso@nota.ai
          </a>
        </div>
      </div>
      <RightSide />
    </div>
  );
};

export default LoginPage;
