import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { postToken } from "./apis";
import {
  applyAccessToken,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "./token";

export const clientWithoutToken = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const clientWithToken = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

let isRefreshing = false;

const setIsRefreshing = (bool) => {
  isRefreshing = bool;
};

const failedTaskQueue = [];

const enroleFailedTask = (request, resolve) => {
  failedTaskQueue.push([request, resolve]);
};

const resolveFailedTask = () => {
  const accessToken = getAccessToken();
  while (true) {
    const failedTask = failedTaskQueue.shift();
    if (failedTask) {
      const request = failedTask[0];
      const resolve = failedTask[1];
      request.headers.Authorization = `Bearer ${accessToken}`;
      resolve(request);
    } else {
      break;
    }
  }
};

const resetFailedTask = () => {
  failedTaskQueue.length = 0;
  isRefreshing = false;
};

export const renewToken = async () => {
  setIsRefreshing(true);
  try {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const response = await postToken({
      accessToken: accessToken,
      refreshToken: refreshToken,
    });
    // 토큰 발급 성공: 쌓였던 실패 요청 재실행
    const { access_token, refresh_token } = response.data;
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
    applyAccessToken(access_token);
    resolveFailedTask();
  } catch (error) {
    // 토큰 발급 실패: 로그인 페이지로 이동
    alert("Please login again");
    resetFailedTask();
    window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/signin`);
  }
  setIsRefreshing(false);
};

/**
 * 요청시 자동 토큰 복구
 */
clientWithToken.interceptors.request.use(
  /**
   * @param {import('axios').AxiosRequestConfig} request
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  async (request) => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (!(accessToken && refreshToken)) {
      // 토큰이 없는 경우: 비정상적인 접근
      return Promise.resolve(request);
    } else if (refreshToken && accessToken) {
      // 토큰이 존재하는 경우: 유효시간 확인 후 재발급
      const expireTimestamp = jwtDecode(accessToken).exp;
      const currentTimestamp = dayjs().unix();
      if (expireTimestamp - currentTimestamp < 60) {
        console.log(
          "Try automatic token reissue because of time expired of access token"
        );
        if (!isRefreshing) renewToken();
        return new Promise((resolve) => {
          enroleFailedTask(request, resolve);
        });
      } else {
        return Promise.resolve(request);
      }
    }
  },
  /**
   * @param {import('axios').AxiosError} error
   * @returns {Promise<import('axios').AxiosError>}
   */
  (error) => Promise.reject(error)
);

/**
 * 에러 받았을 경우 전역 처리
 */
clientWithToken.interceptors.response.use(
  /**
   * @param {import('axios').AxiosResponse} response
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  (response) => Promise.resolve(response),
  /**
   * @param {import('axios').AxiosError} error
   * @returns {Promise<import('axios').AxiosError>}
   */
  async (error) => {
    if (!error.response) {
      console.log(error);
    } else if (error.response.status === 401) {
      // 인증 에러: 토큰 자동 재발급
      // const { config: request } = error;
      // console.log("Try automatic token reissue because of 401 error");
      // if (!isRefreshing) renewToken();
      // return Promise((resolve) => {
      //   enroleFailedTask(request, resolve);
      // });
    } else if (error.response.status === 500) {
      // 서버 로직 에러
      alert(
        "Error is occured in server.\nWe apologize for the inconvenience.\nIf this error occurs continuously, please send us an email."
      );
    } else if (error.response.data.detail) {
      // 세부 에러 내용 있을 경우 디스플레이
      alert(error.response.data.detail);
    } else {
      // 알 수 없는 에러
      alert(`An unexpected error occurred.`);
    }
    return Promise.reject(error);
  }
);
