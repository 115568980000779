import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { useQuery } from "@tanstack/react-query";
import { memo, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "src/components/Button";
import { getUser } from "src/library/apis";
import useStore from "src/stores";

const pageNameByPath = {
  "/models": "Models",
  "/models/upload": "Upload a model",
  "/compression": "Select Compression Mode",
  "/compression/automatic": "Automatic Compression",
  "/compression/advanced": "Advanced Compression",
};
const getBreadscrum = (path) => {
  const pathSlice = path
    .split("/")
    .splice(1)
    .map((path) => `/${path}`);
  const breadscrum = [];
  let currentPath = "";
  for (const path of pathSlice) {
    currentPath += path;
    if (pageNameByPath[currentPath]) {
      breadscrum.push({ path: currentPath, name: pageNameByPath[currentPath] });
    } else {
      breadscrum.push({ path: currentPath, name: path.slice(1) });
    }
  }
  return breadscrum;
};
const Header = () => {
  const location = useLocation();
  const { user, modal } = useStore();
  const [open, setOpen] = useState(false);
  const [pageName, setPageName] = useState("");
  const GetUser = useQuery(["user"], getUser);
  useEffect(() => {
    if (pageNameByPath[location.pathname]) {
      setPageName(pageNameByPath[location.pathname]);
    } else {
      setPageName(location.pathname.split("/").reverse()[0]);
    }
  }, [location.pathname]);
  const dropDownMenu = useRef();
  const handleToggleMenu = (e) => {
    setOpen(!open);
  };
  const handleClickOutside = (e) => {
    if (!dropDownMenu.current.contains(e.target)) {
      setOpen(false);
    }
  };
  const handleClickSignout = () => {
    setOpen(false);
    user.signout();
    window.location.assign(`${process.env.REACT_APP_PORTAL_URL}`);
  };
  const handleClickCredit = () => {
    window.open("https://docs.netspresso.ai/docs/credit");
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div className="h-16 bg-white flex items-stretch justify-between shadow-sm z-10">
      <div className="p-2">
        <div className="text-gray-500 text-xs flex">
          {getBreadscrum(location.pathname).map(({ path, name }, index) => (
            <div key={path}>
              {index !== 0 && <span>&nbsp;&gt;&nbsp;</span>}
              <Link to={path} className="text-gray-500 no-underline">
                {name}
              </Link>
            </div>
          ))}
        </div>
        <div className="font-['Gilroy'] text-2xl font-bold whitespace-nowrap">
          {pageName}
        </div>
      </div>
      <div className="flex">
        <div
          ref={dropDownMenu}
          className="h-full relative flex items-center justify-center"
        >
          <button
            data-cy="header-profile-button"
            onClick={handleToggleMenu}
            className="h-full focus:outline-none text-gray-600 whitespace-nowrap"
          >
            <AccountCircleIcon />
            &nbsp;
            {GetUser?.data?.data?.email}
            <KeyboardArrowDownIcon fontSize="small" />
          </button>
          {open && (
            <div
              className="text-gray-600 absolute top-full w-full right-0 min-w-[48]
            z-10 bg-white border-gray-200 border"
            >
              <ul className="m-0">
                <li>
                  <button
                    data-cy="header-signout-button"
                    onClick={handleClickSignout}
                    className="block p-2 w-full hover:bg-gray-300 focus:outline-none"
                  >
                    <div className="flex align-center gap-1">
                      <LogoutIcon fontSize="small" />
                      <div>Signout</div>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="p-2 flex items-center">
          <Button
            size="sm"
            onClick={handleClickCredit}
          >{`${GetUser?.data?.data?.credit} Credit`}</Button>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
