const law = `PRIVACY POLICY
Nota Inc. (hereinafter referred to as the “Company” or “We”) respects privacy of individual users (“User” or “You”) and is committed to protecting it through the Company’s compliance with this Privacy Policy (hereinafter referred to as this “Policy”). This Policy is applicable to personal data collected in its website (“Website”) operated by the Company.

The Company is the controller of the personal data provided by a User or collected from a User. This Policy describes the types of personal data the Company may collect from a User or that User may provide, and the Company’s practices and purpose for collecting, using, maintaining, protecting, and disclosing the personal data. The Company endeavors to process user’s personal data in compliance with the European Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation or “GDPR”), and with the Korean Personal data Protection Act (“PIPA”) and any other applicable data protection law.

This Policy shall take effect on 2021.01.12 and, in case of modification thereof, the Company will make public notice of it by posting them on the Company’s Website or by using other methods (via text, e-mail, messenger or pop-up screen requesting acceptance at the time of a sign-in).

Terms used in this Policy shall have the meaning set forth in the relevant laws and regulations and the Company's terms and conditions; other matters shall be subject to general commercial practice.
 

I. NAME AND ADDRESS OF THE PERSON RESPONSIBLE

The person who is responsible for the purposes of the GDPR and other applicable data protection laws of the Member States as well as other data protection regulations under this Policy is as provided below:

Nota AI GmbH 
Charlottenstraße 18 
c/o KIC Europe GmbH
Berlin 10117
Germany


II. NAME AND ADDRESS OF THE DATA PROTECTION OFFICER

The data protection officer of those who are accountable:

Haejin Lee
haejin.lee@nota.ai
+49(0)1788247728
Charlottenstraße 18
c/o KIC Europe GmbH
Berlin 10117
Germany

 
III. GENERAL INFORMATION ON DATA PROCESSING

1. Scope of processing personal data
In principle, we only process the personal data of our users to the extent necessary in order to provide a functioning website with our content and services. The processing of personal data takes place regularly, and only with the consent of the user in question. Exceptions include cases whereby prior consent could not be obtained and that the processing of the data is, nonetheless, permitted by law as provided in Section 2 below.

2. Legal basis for data processing
Section 1 of Article 6 under GDPR serves as the legal basis to process their data. Such legal basis are as follows: 

   1) Under point (a) of Section 1 under Article 6 of GDPR, we may obtain consent from the data subject to process their personal data.
   2) Point (b) of Section 1 under Article 6 of GDPR allows processing of personal data required for the performance of a contract of which the data subject is party. This also applies to processing operations required to carry our pre-contractual activities.
   3) Point (c) of Section 1 under Article 6 of GDPR serves as the legal basis when it is necessary to process personal data in order to fulfil a legal obligation to which our company is subject.
   4) Point (d) of Section 1 under Article 6 of GDPR seves as the legal basis in the event that the interest or well-being of the data subject or any relevant natural person(s) require the processing of personal data.
   5) Point (f) of Section 1 under Article 6 of GDPR serves as the legal basis if the processing of data is necessary to safeguard the legitimate interests of our company or that of a third-party operator, and the fundamental rights and freedoms of the person(s) concerned do not outweigh the interest of the former.


3. Data removal and storage duration
The personal data of the data subject will be deleted or blocked as soon as the purpose of storage has been accomplished. In addition, such storage may be provided by the European or national legislator within the EU regulations, law, or other relevant regulations to which the data controller is subject. Blocking or deletion of the data also takes place when the storage period stipulated by the aforementioned standards expires, unless there is a need to prolong the storage of the data for the purpose of concluding or fulfilling the respective contract. However, the Company may retain the following data for the duration as set forth below, as required by relevant laws and regulations of Korea:

   1) Log record of Users such as internet/data detecting the place of User connection: 3 months (The Protection of Communications Secrets Act of Korea)
   2) Record regarding contract or withdrawal of subscription in case of electronic commerce: 5 years (The Act on Consumer Protection in Electronic Commerce of Korea) 
   3) Record on consumer complaint or dispute treatment in case of electronic commerce: 3 years (The Act on Consumer Protection in Electronic Commerce of Korea)


IV. RIGHTS OF THE PERSON CONCERNED

When your personal data is processed, you are subsequently a “person concerned” and have the following rights vis-à-vis the Company.
1. Right of Access 
You may ask the Company to confirm whether your personal data is processed by us. If such processing is available, you can request the following information from the Company:
 

   1) the purpose for which the personal data is processed;
   2) the categories of personal data being processed;
   3) the recipients or categories of recipients to whom the personal data relating to you have been disclosed or are still being disclosed;
   4) the planned duration of the storage of your personal data or, if specific information is not available, criteria for determining the duration of storage;
   5) the existence of a right to rectification or erasure of personal data concerning you, a right to restriction of processing by the data controller or a right to object to such processing;
   6) the existence of a right of appeal to a supervisory authority;
   7) all available information on the source of the data if the personal data is not collected from the data subject; or
   8) the existence of automated decision-making including profiling under Articles 22 (1) and (4) of GDPR and, in certain cases, meaningful information about the data processing system involved, and the scope and intended result of such processing on the data subject.


You have the right to request information on whether your personal data will be transmitted to a third-party country or an international organization. Given that this applies, you can then request for the appropriate guarantees in accordance with Article 46 of GDPR in connection with the transfer. Whenever the Company transfers User’s personal data out of the European Economic Area (“EEA”), the Company will ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
 

      i. The Company will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. 
      ii. Where the Company uses certain service providers, the Company may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. 
 

Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA or Korea.


2. Right to rectification
You have a right to rectification and/or completion to the Company, if the personal data we process is incorrect or incomplete. The Company must make the correction without delay.

3. Right to the restriction of processing
You may request the restriction of the processing of your personal data under the following conditions:
 

   1) if you challenge the accuracy of your personal data within a period of time that enables the data controller to verify the accuracy of your personal data;

   2) the processing is unlawful, and you refuse the deletion of the personal data and instead demand the restriction of the use of the personal data;

   3) the representative no longer needs the personal data for the purpose of processing, but you need it to assert, exercise or defend legal claims; or

   4) if you have objected to the processing pursuant to Article 21, Section 1 of GDPR and it is not yet certain whether the legitimate reasons of the Company outweigh your reasons.


If the processing of personal data concerning you has been restricted, such personal data may, with the execution of data storage, only be used with your consent or for the purpose of asserting, exercising or defending legal claims or protecting the rights of another natural or legal person or for reasons of important public interest, interest to the Union, or a Member State.

If the limitation of the processing after the above mentioned conditions are restricted, you will be informed by the Company before the restriction is lifted.

4. Right to Erasure

   1) Obligation to delete
If you request the Company to delete your personal data with immediate effect, the Company is required to do so immediately given that one of the following applies:
 

      i. Your personal data is no longer necessary for the purposes for which it was collected or processed.

      ii. You revoke your consent, to which the processing is allowed pursuant to Article 6, Section 1 or Article 9, Section 2, Point (a) of GDPR, and there is no other legal basis for processing the data.

      iii. You object to the processing of the personal data pursuant to Article 21, Section 1 of GDPR, unless the Company demonstrates compelling legitimate grounds for the processing which override your interests, rights, and freedom or for the establishment, exercise or defend of legal claims, or you object pursuant to Article 21, Section 2 of GDPR. 

      iv. Your personal data have been processed unlawfully.

      v. The act of deleting your personal data will invoke a legal obligation under the Union law or the laws of the Member States to which the Company is subject.

      vi. Your personal data were collected in relation to information society services being offered pursuant to Article 8, Section 1 of GDPR.

​

   2) Information to third parties
If the Company has made your personal data public and has to delete the data pursuant to Article 17 Section 1 of GDPR, the Company shall take appropriate measures, including technical means, to inform data controllers who process the personal data that you have been identified as being affected, that a request has been made to delete all links to such personal data or copies or replications of the personal data, taking into account available technology and implementation costs to execute the process.

   3) Exceptions
Your personal data may not be deleted if the processing is necessary.
 

      i. to exercise the right to freedom of speech and information;

      ii. to fulfill a legal obligation required by the law of the Union or of the Member States to which the representative is subject, or to perform a task of public interest or in the exercise of public authority delegated to the representative;

      iii. for reasons of public interest in the field of public health pursuant to Article 9 Section 2, Point (h) and Article 9 Section 3 of GDPR;
, or

   4) to enforce, exercise or defend legal claims.

   

5. Right to information
If you exercise the right of rectification, deletion or restriction of processing over the Company, the Company will notify all recipients to whom your personal data have been disclosed of the correction or deletion of the data or restriction of processing, unless this proves to be impossible or involves a disproportionate effort.

You reserve the right to be informed about the recipients of your data by the Company.

6. Right to Data Portability
You have the right to receive your personal data given to the Company in a structured, standard and machine-readable format. In addition, you have the right to transfer this data to another person without hindrance by the Company who was initially given the data, given that

   1) the processing is based on a consent in accordance with Article 6, Section 1, Point (a) or Article 9, Section 2, Point (a) of GDPR, or based on a contract in accordance with Article 6, Section 1, Point (b) of GDPR; and

   2) the processing is done by automated means.


In exercising this right, you also have the right to maintain that your personal data are transmitted directly from one person to another, insofar as this is technically feasible. Freedoms and rights of other persons shall not be affected.

The right to data portability does not apply to the processing of personal data necessary for the performance of a task in the public interest or in the exercise of official authority delegated to the Company.


7. Right to object

   1) Subjective to your situation, you have, at any time, the right to object against the processing of your personal data pursuant to Article 6, Section 1, Point (e) or (f) of GDPR; this also applies to profiling based on these provisions.

   2) The Company will no longer process the personal data concerning you unless we can demonstrate compelling legitimate grounds for processing that outweigh your interests, rights and freedoms, or the processing is for the purpose of enforcing, exercising or defending legal claims.

   3) If you personal data are processed for direct marketing purposes, you have the right to object at any time to the processing of your personal data in regard to such advertising; this also applies to profiling insofar as it is associated with direct mail.

   4) If you object to processing for direct marketing purposes, your personal data will no longer be processed for these purpose.

   5) Regardless of Directive 2002/58/EC, you have the option, in the context of the use of information society services, to exercise your right to object to automated procedures that use technical specifications.


8. Right to revoke the data protection consent declaration
You have the right to withdraw your data protection declaration at any time. The revocation of consent does not affect the legality of the processing carried out on the basis of the consent until the revocation.

9. Automated decision on a case-by-case basis, including profiling
You have the right not to subject to a decision based solely on automated processing - including profiling - that will have legal effect or affect you in a similar manner. This does not apply if the decision

   1) is required for the conclusion or execution of a contract between you and the data controller,

   2) is permitted by the Union or Member State legislation to which the data controller is subject, and where such legislation contains appropriate measures to safeguard your rights and freedoms and legitimate interests, or

   3) with your expressed consent.
 

However, these decisions must not be based on special categories of personal data under Article 9, Section 1 of GDPR, unless Article 9, Section 2, Point (a) or (g) of GDPR applies and reasonable measures have been taken to protect the rights and freedoms as well as your legitimate interests.

With regard to the cases referred to in (1) and (3) above, the Company shall take appropriate measures to uphold your rights and freedoms as well as your legitimate interests, including the right to obtain assistance from the data controller or their representative, to express your opinion on the matter, and to contest the decision.

10. Right to complain to a supervisory authority
Without prejudice to any other administrative or judicial remedy, you shall have the right to complain to a supervisory authority, in the Member State of their residence, place of work or place of alleged infringement, if you believe that the processing of the personal data concerning you violates the GDPR.

The supervisory authority to which the complaint has been submitted shall inform the complainant of the status and results of the complaint, including the possibility of a judicial remedy pursuant to Article 78 of GDPR.11. Exceptions to Archival Purposes.
 
Notwithstanding the foregoing, for archival purposes of public interest, scientific or historical research purposes or for statistical purposes pursuant to Article 89 Section 1 of GDPR, your rights referred to in this Sections 1, 2, 3 and 7 of Article IV under this Policy may be derogated to the extent such rights are likely to render impossible or seriously affect the achievement of the objectives of that processing under this paragraph, provided that the Company uses appropriate safeguards as described in Article V below to ensure respect for the principle of data minimization. 


V. MEASURES TO PROTECT PERSONAL DATA
In order to prevent the loss, theft, unauthorized disclosure, leakage, alteration or damage of personal information of the Users, the Company has implemented system of protection. Such measures include technical and managerial measures for security as follows:

1. Technical Measures

   1) Protect personal information with passwords

   2) Preventive measures against data leakage due to hacking and computer virus

   3) Encrypt and pseudonymize certain personal information

   4) Transmit personal information using security servers


2. Managerial Measures

   1) Appoint a person in charge of protecting personal information to limit access to personal information 

   2) Provide education and training for the person in charge on a regular basis to ensure compliance with the Company’s Policy  

   3) Ensure that the Company and its personnel are in compliance with the Policy, and promptly correct any non-compliance.


VI. PROVISION OF WEBSITE AND CREATION OF LOG FILES
1. Description and scope of data processing
Each time our website is accessed, our system automatically collects data and relevant information from the computer system of the calling computer. The following data is collected:
 

   1) Browser type and version used

   2) The user’s operating system

   3) The user’s internet service provider

   4) The IP address of the user

   5) Date and time of access

   6) Web pages from which the user’s system accesses our website

   7) Web pages accessed by the user’s system through our website


The data is also stored in the log files of our system. The data is not stored with the user’s other personal data.

2. Legal basis for data processing
The legal basis for the temporary storage of data and logfiles is Article 6, Section 1, Point (f) of GDPR.

3. Purpose of data processing

   1) The temporary storage of the IP address by the system is necessary for the delivery of the website to the computer of the user. For this purpose, the user’s IP address must be kept for the duration of the session.

   2) Storage in log files is done to ensure the functionality of the website. In addition, the data is used to optimize the website and to ensure the security of our information technology (IT) systems. In this case, the evaluation of the respective data for marketing purposes does not take place.


For the aforementioned purposes, our legitimate interest lies in the processing of data in compliance with Article 6, Section 1, Point (f) of GDPR.

4. Duration of storage
The data will be deleted as soon as it is no longer necessary for the purpose of its collection. The session is complete when the collection of data for the provision of the website is accomplished. The data stored in log files will only be stored for a period of up to seven days. Prolonged storage is possible when the IP addresses of the users are deleted or alienated, and that the assignment of the calling client is no longer possible.

5. Objections and removal option
The collection of data for the provision of the website as well as the storage of data in log files are essential for the operation of the website. Therefore, the user may not object to the aforementioned processes
 

VII. USE OF COOKIES

1. Description and scope of data processing
Our website uses cookies. Cookies are text files that are stored in the internet browser or the internet browser on the user’s computer system. If a user calls up a website, a cookie can be stored on the user’s operation system. These cookies contain a string of characters that allows the browser to be uniquely identified when the website is reopened. We use cookies to make our website more user-friendly. Some elements of our website require the calling browser to be identified even after a page break. The following data is stored and transmitted in the cookies:

Session-Key

Our website also uses Google Analytics, an analytics service provided by Google, Inc. Google Analytics to better understand how visitors use our site, for example, which pages are viewed, how long customers spend visiting a particular page and how often they return to visit our site. Google Analytics uses cookies to collect non-identifying information, which may be transmitted to and stored by Google on servers in the United States. Google provides some additional privacy options described at www.google.com/policies/privacy/partners regarding Google Analytics cookies.

2. Legal basis for data processing
The legal basis for the processing of personal data using cookies is Article 6, Section 1, Point (f) of GDPR.

3. Purpose of data processing
The purpose of using technically necessary cookies is to simplify the use of websites for users. Some features on our website cannot be offered without the use of cookies. For these features, it is necessary that the browser is recognized even after a page break.

We require cookies for the following features:

Seminar-Sign In, Frontend-Login

The data collected through the technically necessary cookies will not be used to create profiles of the users.

4. Duration of storage, objections and removal option
Cookies are stored on the computer of the user and are transmitted to us. Therefore, as a user, you have full control over the use of cookies. By changing the settings in your internet browser, you can disable or restrict the transmission of cookies. Cookies that have been saved can be deleted at any time. This may also occur automatically. If cookies are disabled for our website, it may not be possible to use all the features on the website to its full potential. The transmission of Flash cookies cannot be prevented by the settings on the internet browser, but by changing the setting on the Flash Player instead.


VIII. NEWSLETTER
1. Description and scope of data processing
You can subscribe to a free newsletter on our website. The following data from the input mask are transmitted to us when the user subscribes for the newsletter.

   1) email address;

   2) name;

   3) date and time of the calling computer; and 

   4) salutation


To process your data, your consent will be obtained, and you will be referred to this Policy.

If your purchase goods or services on our website and provide your email address during the process, your email address may subsequently be used for us to send you newsletters. In such an event, the newsletter will only send you mail that are relevant or similar to the goods and/services that your purchased.

When personal data is processed for the purpose of sending newsletters, there will be no disclosure of the said data to third party operators. The data will hence be used solely for the purpose of sending newsletters.

2. Legal basis for data processing
The legal basis for the processing of data after the user has subscribed for the newsletter, and given their consent, is Article 6, Section 1, Point (a) of GDPR.

The legal basis for sending newsletters as a result of the sale of goods or services is Article 7 Parargraph 3 of the German Act Against Unfair Competition (UWG).

3. Purpose of data processing
The collection of the user’s email address is to deliver the newsletter.

The collection of other personal data during the subscription process serves to prevent the misuse of services or the misuse of the email address provided.

4. Duration of storage
The data will be deleted as soon as they have completed their use for the survey. The user’s email address will be saved for the period at which the newsletter is active.

Other personal data collected during the subscription process are typically deleted after seven days.

5. Objections and removal option
Users may terminate his or her subscription to the newsletter any time by opting out. Each newsletter will include a link that offers this option. The user may also revoke their consent to the storage of their personal data collected during the subscription process.


IX.  CONTACT FORM AND EMAIL CONTACT

1. Description and scope of data processing
A contact form is available on our website, which may be used for electronic communication. If the user chooses this method of communication, the data entered in the input mask will be transmitted to us and saved.
When the message is being sent, the following data will be stored:

   1) email address

   2) name

   3) surname

   4) contact number

   5) IP address of the calling computer

   6) date and time of registration

   7) thematic assignment


For the purpose of dispatch operations, you are referred to this Policy and your consent is obtained for the processing of your personal data.

Alternatively, contact via email can also be made, in which case, the user’s personal data transmitted via email will be stored. For this purpose, your personal data will not be disclosed to third parties. The data will be used exclusively for the purpose of processing the conversation.

2. Legal basis for data processing
With the consent of the user, the legal basis for the processing of their data is pursuant to Article 6, Section 1, Point (a) of GDPR. The legal basis for the processing of data transmitted in the course of sending an email is Article 6, Section 1, Point (f) of GDPR. If the contact via email is made for the conclusion of a contract, Article 6, Section 1, Point (b) of GDPR also applies.

3. Purpose of data processing
The processing of personal data obtained from the input mask serves only to process the contact that has been established. If contact is made via email, the legitimate interest of processing the data will be considered.
Other personal data transmitted during the dispatch process is only processed to prevent the misuse of the contact form and to ensure the security of our information technology (IT) systems.

4. Duration of storage
The data will be deleted as soon as it is no longer necessary for the purpose of its collection. As for the data collected from the contact form, as well as those retrieved via email, the data will be deleted when the conversation with the user has ended. The conversation is considered done when it can be inferred from the content of the conversation that the relevant topics discussed have been clarified.
The additional personal data collected during the dispatch process will be deleted after a period of seven (7) days at the latest.

5. Objections and removal possibility
The user may, at any time, revoke their consent to the processing of their personal data. If the user contacts us via email, they may object to the storage of their personal data at any time. In this case, the conversation cannot continue. All personal data stored in the course of communication with the user will hence be deleted.
 

XI. APPLICATION FORM AND APPLICATION VIA EMAIL
 
1. Extent of personal data processing

   1) An application is available on our website which can be used for online applications. If an applicant chooses this option, the data entered in the input mask will be transmitted to us and saved. These data include:
      i.    [Name, email address, phone number, user’s inquiries]
In the course of processing your data, a dispatching process will take place. When this occurs, we will refer you to this Policy and obtain your consent for this specific action.

   2) Alternatively, you can send us your application via email. In this case, we will record your email address as well as the information you provide in the email. After submitting your application, you will receive a confirmation email from us.

   3) There is no transfer of your data to third parties. The data will be used exclusively for the processing of your application.


2. Legal basis of data processing
The legal basis for the processing your data is Article 6, Section 1, Point (a) of GDPR  and Section 26 of Federal Data Protection Act of Germany (BDSG).

3. Purpose of data processing
The processing of the personal data from the application form only serves to process your application. If contact is made via e-mail, we may also process the data found in the contents of the transmitted messages.
The other personal data processed during the dispatching process is intended to prevent any misuse of the application form and to ensure the security of our information technology (IT) systems.

4. Duration of storage
After completing the application process, the data will be saved up to undefined. Your data will, at latest, be deleted at the end of the stipulated storage period. In the event of a legal obligation, the data will be stored under applicable provisions.

The additional personal data collected during the dispatching process will be deleted, at the latest, after a period of seven (7) days.

5. Objections and removal option
The applicant has the possibility, at any time, to revoke their consent to the processing of their personal data. If the applicant contacts us by e-mail, they may also object to the storage of their personal data at any time. In this case, the application will no longer be valid, and all personal data stored in electronic applications will be deleted.


XII. MISCELLANEOUS
This Policy will not be applicable to the collection and processing of personal information performed by third party service providers, such as any websites that are linked to our Website. 

Last modified: 2021.04.28
 

IMPRINT

HQ: Room 2219, Changjo-gwan, 193, Munji-ro, Yuseong-gu, Daejeon, Republic of Korea
German office: Charlottenstr.18, 10117 Berlin
Amtsgericht/Handelskammer Register-Nr.: HRB 216399 B
 `;

export default law;
