import { ReactComponent as NetsPressoLoginLogo } from "../../assets/svgs/NetsPresso-Login.svg";
import { ReactComponent as NotaLoginLogo } from "../../assets/svgs/Nota-Login.svg";

const RightSide = () => {
  const handleClickBottomLogo = () => {
    window.open("https://nota.ai/");
  };
  return (
    <div className="p-8 flex flex-col items-stretch justify-between flex-1">
      <div />
      <div className="font-['Gilroy'] flex items-center justify-center">
        <div className="w-fit flex flex-col">
          <div className="h-[70px]">
            <NetsPressoLoginLogo />
          </div>
          <div className="mt-12 text-5xl text-gray-600">
            <span className="text-[#005197]">Lighter</span>,<br />
            <span className="text-[#005197]">Faster</span>,<br />
            <span className="text-[#005197]">Easier</span>.<br />
            <span className="text-[#005197]">NetsPresso Model Compressor</span>
          </div>
        </div>
      </div>
      <div className="flex items-end justify-end gap-4">
        <span>Copyright @ Nota Inc. All rights reserved.</span>
        <button type="button" onClick={handleClickBottomLogo}>
          <div className="h-[25px]">
            <NotaLoginLogo />
          </div>
        </button>
      </div>
    </div>
  );
};

export default RightSide;
