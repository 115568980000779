import { observable } from "mobx";

const modal = observable({
  passwordChangeModalShow: false,
  creditMessageModalShow: false,
  updateNotesModalShow: false,
  setPasswordChangeModalShow(bool) {
    this.passwordChangeModalShow = bool;
  },
  setCreditMessageModalShow(bool) {
    this.creditMessageModalShow = bool;
  },
  setUpdateModalShow(bool) {
    this.updateNotesModalShow = bool;
  },
});

export { modal };
