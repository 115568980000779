import PropTypes from "prop-types";
const Badge = (props) => {
  return (
    <div
      {...props}
      className={`inline h-fit px-3 py-1 rounded-full text-sm
        ${
          props.color === "black"
            ? "text-white bg-gray-800"
            : props.color === "mint"
            ? "text-[#0A545F] bg-[#AFECF4]"
            : props.color === "gray"
            ? "text-gray-800 bg-gray-300"
            : ""
        }
      `}
    />
  );
};

Badge.propTypes = {
  color: PropTypes.oneOf(["black", "mint", "gray"]),
};

Badge.defaultProps = {
  color: "black",
};

export default Badge;
