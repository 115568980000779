import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getUser } from "src/library/apis";
import {
  applyAccessToken,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "src/library/token";
import useStore from "src/stores";
import Spinner from "../../components/Spinner";
import Footer from "./Footer";
import Header from "./Header";
import Modals from "./Modals";
import SideBar from "./SideBar";

const Dashboard = () => {
  const { user } = useStore();
  const [status, setStatus] = useState("loading");
  const GetUser = useQuery(["user"], getUser, {
    enabled: false,
    onSuccess: ({ data }) => {
      user.setUserInfo(data);
      setStatus("done");
    },
    onError: () => {
      user.resetUserInfo();
      alert("Please login again");
      window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/signin`);
    },
    retry: false,
  });
  useEffect(() => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (accessToken && refreshToken) {
      // 스토리지에 토큰이 남아있음
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      applyAccessToken(accessToken);
      GetUser.refetch();
    } else {
      // 로그인 할 수 있는 정보가 존재하지 않음
      window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/signin`);
    }
  }, []);

  if (status === "loading") {
    return (
      <div
        className="w-screen h-screen flex items-center justify-center"
        style={{ width: "100vw", height: "100vh" }}
      >
        <Spinner />
      </div>
    );
  } else if (status === "done") {
    return (
      <div className="w-screen h-screen flex">
        <SideBar />
        <div className="flex-1 flex flex-col">
          <Header />
          <div className="overflow-auto flex-1 flex flex-col bg-gray-100">
            <div className="flex-1">
              <div className="p-4">
                <Outlet />
              </div>
              <Modals />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

export default Dashboard;
