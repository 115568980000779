import Cookies from "js-cookie";
import { clientWithToken } from "./axios";

export const setAccessToken = (accessToken) => {
  Cookies.set("access_token", accessToken, {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

export const setRefreshToken = (refreshToken) => {
  Cookies.set("refresh_token", refreshToken, {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

export const applyAccessToken = (accessToken) => {
  clientWithToken.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

export const getAccessToken = (accessToken) => {
  return Cookies.get("access_token");
};

export const getRefreshToken = (refreshToken) => {
  return Cookies.get("refresh_token");
};

export const removeTokens = () => {
  Cookies.remove("access_token", {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  Cookies.remove("refresh_token", {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  clientWithToken.defaults.headers.Authorization = null;
};
