import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from "@mui/material";
import { useContext } from "react";
import tooltip from "src/constants/tooltip";
import Button from "../../../components/Button";
import LabelTitle from "../../../components/LabelTitle";
import QuestionTooltip from "../../../components/QuestionTooltip";
import Select from "../../../components/Select";
import Spinner from "../../../components/Spinner";
import TextInput from "../../../components/TextInput";
import { AdvancedCompressionContext } from "../Advanced/AdvancedCompressionProvider";

const methods = {
  FD_TK: ["vbmf"],
  FD_SVD: ["vbmf"],
  PR_L2: ["lamp"],
  PR_GM: ["lamp"],
  PR_NN: ["lamp"],
};
const methodInfo = {
  vbmf: {
    label: "VBMF(Variational Bayesian Matrix Factorization)",
    ratio: {
      label: "Calibration ratio",
      description: "-1 ≤ ratio(float) ≤ 1",
      tooltip: tooltip.compress_recommendation_calibration_ratio,
      defaultValue: 0,
      min: -1,
      max: 1,
      step: 0.01,
    },
  },
  lamp: {
    label:
      "SLAMP(Structured Layer-Adaptive sparsity for the Magnitude-based Pruning)",
    ratio: {
      label: "Pruning ratio",
      description: "0 < ratio(float) ≤ 1",
      tooltip: tooltip.compress_recommendation_pruning_ratio,
      defaultValue: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
    },
  },
};

const RecommendationModal = () => {
  const {
    show,
    input,
    models,
    model,
    compression,
    layers,
    compressedModel,
    GetModels,
    PostCompression,
    PostDataset,
    PostRecommendation,
    PutCompression,
    GetCompressedModel,
    GetUser,
    handleChangeInput,
    handleClickToggleShow,
    handleChangeDatasetFile,
    handleClickResetAll,
    handleClickSelectMethod,
    handleClickClearInputs,
    handleClickModify,
    handleClickCompress,
    handleClickRecommendation,
  } = useContext(AdvancedCompressionContext);

  return (
    <Dialog open={show.recommendationModal} fullWidth>
      <DialogTitle>
        <div className="flex justify-between">
          <div>Set recommendation</div>
          <button
            type="button"
            data-name="recommendationModal"
            onClick={handleClickToggleShow}
            className="focus:outline-none disabled:opacity-50"
            disabled={PostRecommendation.isLoading}
          >
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-3">
            <div className="flex items-center gap-1">
              <LabelTitle>Method</LabelTitle>
              <QuestionTooltip
                content={
                  tooltip.compress_recommendation_method_filter_decomposition
                }
              />
            </div>
            <div className="col-span-2">
              <Select
                name="recommendationMethod"
                value={input.recommendationMethod}
                onChange={handleChangeInput}
                disabled={PostRecommendation.isLoading}
              >
                {methods[compression.compression_method].map(
                  (recommendationMethod) => (
                    <option
                      key={recommendationMethod}
                      value={recommendationMethod}
                    >
                      {methodInfo[recommendationMethod].label}
                    </option>
                  )
                )}
              </Select>
            </div>
          </div>
          {input.recommendationMethod && (
            <div className="grid grid-cols-3">
              <div>
                <div className="flex items-center gap-1">
                  <LabelTitle>
                    {methodInfo[input.recommendationMethod].ratio.label}
                  </LabelTitle>
                  <QuestionTooltip
                    content={
                      methodInfo[input.recommendationMethod].ratio.tooltip
                    }
                  />
                </div>
                <div>
                  {methodInfo[input.recommendationMethod].ratio.description}
                </div>
              </div>
              <div className="col-span-2 flex items-center gap-3">
                <div className="w-16">
                  <TextInput
                    name="ratio"
                    value={input.ratio}
                    onChange={handleChangeInput}
                    disabled={PostRecommendation.isLoading}
                  />
                </div>
                <div className="w-80">
                  <Slider
                    name="ratio"
                    value={input.ratio}
                    onChange={handleChangeInput}
                    min={methodInfo[input.recommendationMethod].ratio.min}
                    max={methodInfo[input.recommendationMethod].ratio.max}
                    step={methodInfo[input.recommendationMethod].ratio.step}
                    disabled={PostRecommendation.isLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex items-center gap-2">
          {PostRecommendation.isLoading && <Spinner />}
          <Button
            color="gray"
            data-name="recommendationModal"
            onClick={handleClickToggleShow}
            disabled={PostRecommendation.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClickRecommendation}
            disabled={PostRecommendation.isLoading}
          >
            Do Recommendation
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendationModal;
