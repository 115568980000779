import React from "react";

const TextArea = React.forwardRef((props, ref) => {
  return (
    <textarea
      {...props}
      ref={ref}
      className={`w-full p-2 resize-none ${
        props.disabled && "bg-gray-200"
      } border border-gray-300 rounded`}
    ></textarea>
  );
});

export default TextArea;
