import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import Button from "src/components/Button";
import LabelTitle from "src/components/LabelTitle";
import Select from "src/components/Select";
import Spinner from "src/components/Spinner";
import TextInput from "src/components/TextInput";
import { putModel } from "src/library/apis";
import { getMetricUnitsFromTask } from "src/library/utils";

export default function UpdateModal({
  open,
  setOpen,
  GetOriginalModel,
  GetCompressedModel,
}) {
  const originalModel = useMemo(
    () => GetOriginalModel.data.data,
    [GetOriginalModel]
  );
  const compressedModel = useMemo(
    () => GetCompressedModel.data.data,
    [GetCompressedModel]
  );
  const metricUnits = useMemo(
    () => getMetricUnitsFromTask(compressedModel.task),
    [compressedModel]
  );
  const [modelName, setModelName] = useState(compressedModel.model_name);
  const [description, setDescription] = useState(compressedModel.description);
  const [metricUnitSelect, setMetricUnitSelect] = useState("");
  const [metricUnitText, setMetricUnitText] = useState("");
  const [metricValueOriginal, setMetricValueOriginal] = useState(
    originalModel.metric.metric_value
  );
  const [metricValueCompressed, setMetricValueCompressed] = useState(
    compressedModel.metric.metric_value
  );
  const metricUnit = useMemo(() => {
    if (metricUnitSelect === "") {
      return "";
    } else if (metricUnitSelect === "Other") {
      return metricUnitText;
    } else {
      return metricUnitSelect;
    }
  }, [metricUnitSelect, metricUnitText]);
  useEffect(() => {
    const metricUnit = compressedModel.metric.metric_unit;
    if (metricUnits.includes(metricUnit)) {
      setMetricUnitSelect(metricUnit);
      setMetricUnitText("");
    } else {
      setMetricUnitSelect("Other");
      setMetricUnitText(metricUnit);
    }
  }, [compressedModel, metricUnits]);
  const PutModel = useMutation(putModel, {
    onSuccess: ({ data }) => {
      GetOriginalModel.refetch();
      GetCompressedModel.refetch();
      setOpen(false);
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeModelName = (e) => {
    setModelName(e.target.value.replace(/[^0-9a-zA-Z_]+/, ""));
  };
  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleChangeMetricUnitSelect = (e) => {
    setMetricUnitSelect(e.target.value);
    setMetricUnitText("");
  };
  const handleChangeMetricUnitText = (e) => {
    setMetricUnitText(e.target.value);
  };
  const handleChangeMetricValueOriginal = (e) => {
    setMetricValueOriginal(e.target.value);
  };
  const handleChangeMetricValueCompressed = (e) => {
    setMetricValueCompressed(e.target.value);
  };
  const validateInput = () => {
    if (modelName === "") {
      alert("Please enter a model name");
      return false;
    } else if (metricUnitSelect === "Other" && metricUnitText === "") {
      alert("Please enter a evaluation metric unit");
      return false;
    } else if (
      metricUnit &&
      (metricValueOriginal === "" || metricValueCompressed === "")
    ) {
      alert("Please enter a evaluation metric value");
      return false;
    } else if (isNaN(metricValueOriginal) || isNaN(metricValueCompressed)) {
      alert("Metric value should be a numeric");
      return false;
    }
    return true;
  };
  const handleClickUpdate = () => {
    if (!validateInput()) return;
    PutModel.mutate({
      modelId: compressedModel.model_id,
      modelName: modelName,
      description: description,
      metricUnit: metricUnit,
      metricValue: metricValueCompressed,
      originalMetricValue: metricValueOriginal,
    });
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <div>Edit model</div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div>
            <LabelTitle>Model name *</LabelTitle>
            <TextInput
              value={modelName}
              onChange={handleChangeModelName}
              disabled={PutModel.isLoading}
            />
          </div>
          <div>
            <LabelTitle>Memo</LabelTitle>
            <TextInput
              value={description}
              onChange={handleChangeDescription}
              disabled={PutModel.isLoading}
            />
          </div>
          <div>
            <LabelTitle>Evaluation metric</LabelTitle>
            <div className="flex items-center gap-1">
              <div>
                <Select
                  value={metricUnitSelect}
                  onChange={handleChangeMetricUnitSelect}
                  disabled={PutModel.isLoading}
                >
                  <option value="">Select metric</option>
                  {metricUnits.map((metricUnit) => (
                    <option key={metricUnit} value={metricUnit}>
                      {metricUnit}
                    </option>
                  ))}
                  <option value="Other">Other</option>
                </Select>
              </div>
              {metricUnitSelect === "Other" && (
                <div>
                  <TextInput
                    value={metricUnitText}
                    onChange={handleChangeMetricUnitText}
                    disabled={PutModel.isLoading}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <LabelTitle>Evaluation value - Original</LabelTitle>
            <TextInput
              value={metricValueOriginal}
              onChange={handleChangeMetricValueOriginal}
              disabled={PutModel.isLoading || metricUnitSelect === ""}
            />
          </div>
          <div>
            <LabelTitle>Evaluation value - Compressed</LabelTitle>
            <TextInput
              value={metricValueCompressed}
              onChange={handleChangeMetricValueCompressed}
              disabled={PutModel.isLoading || metricUnitSelect === ""}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex items-center gap-2">
          {PutModel.isLoading && <Spinner />}
          <Button
            color="gray"
            onClick={handleClose}
            disabled={PutModel.isLoading}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={handleClickUpdate}
            disabled={PutModel.isLoading}
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
