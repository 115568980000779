import CloseIcon from "@mui/icons-material/Close";
import PasswordIcon from "@mui/icons-material/Password";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { putPassword } from "src/library/apis";
import useStore from "src/stores";
import Button from "../components/Button";
import TextInput from "../components/TextInput";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { modal } = useStore();
  const [changePassword, setChangePassword] = useState({
    password1: "",
    password2: "",
    loading: false,
  });
  const handleChangeInput = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.name]: e.target.value,
    });
  };
  const checkInputs = () => {
    if (!changePassword.password1 || !changePassword.password2) {
      alert("Please enter a password.");
      return false;
    }
    if (changePassword.password1 !== changePassword.password2) {
      alert("Please enter a same password.");
      return false;
    }
    return true;
  };
  const handleClickChangePassword = async () => {
    if (!checkInputs()) {
      return;
    }
    setChangePassword({
      ...changePassword,
      loading: true,
    });
    try {
      await putPassword({
        password: changePassword.password1,
        password_validation: changePassword.password2,
      });
      alert("Password has been changed. Please login again.");
      modal.setPasswordChangeModalShow(false);
      window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/signin`);
    } catch (error) {
      setChangePassword({
        ...changePassword,
        loading: false,
      });
    } finally {
    }
  };
  const handleClose = () => {
    modal.setPasswordChangeModalShow(false);
  };
  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      handleClickChangePassword();
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.passwordChangeModalShow}
      onClose={handleClose}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <div>Change Password</div>
          <button onClick={handleClose} className="focus:outline-none">
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-3">
          <TextInput
            icon={<PasswordIcon />}
            value={changePassword.password1}
            name="password1"
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            onChange={handleChangeInput}
            disabled={changePassword.loading}
            onKeyPress={handlePressEnter}
          />
          <TextInput
            icon={<PasswordIcon />}
            value={changePassword.password2}
            name="password2"
            type="password"
            placeholder="Repeat password"
            autoComplete="new-password"
            onChange={handleChangeInput}
            disabled={changePassword.loading}
            onKeyPress={handlePressEnter}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="gray" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleClickChangePassword}
          disabled={changePassword.loading}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(ChangePassword);
