import React from "react";
import LatencyGuage from "src/components/LatencyGuage";
import { getModelShapeString } from "src/library/utils";

function NetworkGraphNode({
  model,
  layers,
  element,
  setSelectedLayer,
  handleClickCheckLayer,
  maxLatency,
  disabled,
}) {
  const handleClickNode = () => {
    if (element.isAvailable) {
      document
        .getElementById(`table-layer-${element.values.name}`)
        .scrollIntoViewIfNeeded(true);
    }
    setSelectedLayer(element);
  };
  return (
    <div
      onClick={handleClickNode}
      className={`text-sm border border-gray-300 rounded overflow-hidden cursor-pointer ${
        !element.isAvailable && "opacity-30"
      }`}
    >
      {element.values.input_shape && (
        <div className="p-1 text-center text-xs border-b border-gray-300">
          {`${getModelShapeString(element.values.input_shape, "N")}`}
        </div>
      )}
      <div className="p-1">
        <div className="text-center">
          {element.isAvailable ? (
            <label className="flex items-center justify-center gap-1">
              <input
                type="checkbox"
                id={`network-checkbox${element.values.name}`}
                name={`${element.values.name}`}
                data-type={"network"}
                data-layer-name={element.values.name}
                checked={
                  layers.find((layer) => layer.name === element.values.name).use
                }
                onChange={handleClickCheckLayer}
                disabled={disabled}
              />
              <b>{`${element.values.name}`}</b>
            </label>
          ) : (
            <b>{`${element.values.name}`}</b>
          )}
        </div>
        <LatencyGuage
          latency={
            layers
              .find((layer) => layer.name === element.values.name)
              ?.latency.find((e) => e.device_name === model.target_device).value
          }
          max={maxLatency}
        />
      </div>
      {element.values.output_shape && (
        <div className="p-1 text-center text-xs border-t border-gray-300">
          {`${getModelShapeString(element.values.output_shape, "N")}`}
        </div>
      )}
    </div>
  );
}

export default React.memo(NetworkGraphNode);
