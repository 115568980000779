import { useCallback, useEffect, useRef, useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import FileOpenIcon from "@mui/icons-material/FileOpen";

const FileInput = ({
  file,
  onChange,
  disabled,
  drag,
  buttonText,
  inputText,
  dragText,
  "data-cy": dataCy,
}) => {
  const fileInputRef = useRef();
  const handleChangeFile = (e) => {
    onChange(e);
  };
  const handleClickButton = () => {
    fileInputRef.current.value = "";
    fileInputRef.current.click();
  };
  const dragInputRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  const handleDragOut = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    },
    [setIsDragging]
  );
  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files) {
        setIsDragging(true);
      }
    },
    [setIsDragging]
  );
  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (disabled) return;
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(e.dataTransfer.files[0]);
      fileInputRef.current.files = dataTransfer.files;
      handleChangeFile(e);
    },
    [disabled]
  );
  const initDragEvents = useCallback(() => {
    if (dragInputRef.current) {
      dragInputRef.current.addEventListener("dragenter", handleDragIn);
      dragInputRef.current.addEventListener("dragleave", handleDragOut);
      dragInputRef.current.addEventListener("dragover", handleDragOver);
      dragInputRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  const resetDragEvents = useCallback(() => {
    if (dragInputRef.current) {
      dragInputRef.current.removeEventListener("dragenter", handleDragIn);
      dragInputRef.current.removeEventListener("dragleave", handleDragOut);
      dragInputRef.current.removeEventListener("dragover", handleDragOver);
      dragInputRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  useEffect(() => {
    initDragEvents();
    return () => resetDragEvents();
  }, [dragInputRef.current, initDragEvents, resetDragEvents]);
  return (
    <div className="flex flex-col gap-1">
      <div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleChangeFile}
          disabled={disabled}
          className="hidden"
          data-cy={dataCy}
        />
        <div className="flex gap-1">
          <Button onClick={handleClickButton} disabled={disabled}>
            {buttonText ? buttonText : "Select File"}
          </Button>
          <TextInput
            disabled
            value={
              file ? file.name : inputText ? inputText : "Please Select a File"
            }
          />
        </div>
      </div>
      {drag && (
        <div
          ref={dragInputRef}
          className={`w-full h-72 flex flex-col items-center justify-center text-center rounded border-dashed border-gray-300 border-2
          ${
            isDragging && !disabled
              ? "bg-blue-200"
              : isDragging && disabled
              ? "bg-red-200"
              : file
              ? "bg-green-200"
              : "bg-gray-200"
          }`}
        >
          <div>
            <FileOpenIcon fontSize="large" />
          </div>
          <br />
          <div>Drag and Drop your model file in here</div>
          {dragText && dragText}
        </div>
      )}
    </div>
  );
};

export default FileInput;
