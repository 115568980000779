function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tab({ tabs, value, onChange }) {
  const handleClickTab = (e) => {
    onChange(e);
  };
  return (
    <div className="sm:block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.label}
              className={classNames(
                tab.value === value
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
                "no-underline hover:no-underline"
              )}
              aria-current={value ? "page" : undefined}
              onClick={handleClickTab}
              value={tab.value}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
