import { memo } from "react";

const LinkButton = ({ children, disabled, onClick }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className="p-2 text-sm text-white hover:underline disabled:text-gray-400"
    >
      {children}
    </button>
  );
};

export default memo(LinkButton);
