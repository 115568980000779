import React from "react";

const LatencyGuage = ({ latency, max }) => {
  return (
    <div className="relative whitespace-nowrap bg-gray-200 rounded">
      <div
        className={`absolute top-0 left-0 h-full bg-red-300 rounded`}
        style={{
          width: `${(latency / max) * 100}%`,
        }}
      />
      <div className="relative pr-0.5 text-xs text-right">
        {`${latency ? latency.toFixed(2) : "-"} μs`}
      </div>
    </div>
  );
};

export default React.memo(LatencyGuage);
