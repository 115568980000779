import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Slider } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import LabelTitle from "src/components/LabelTitle";
import Paper from "src/components/Paper";
import PhraseTitle from "src/components/PhraseTitle";
import Select from "src/components/Select";
import Spinner from "src/components/Spinner";
import TextArea from "src/components/TextArea";
import TextInput from "src/components/TextInput";
import { getParentsModels, postAutoCompress } from "src/library/apis";
import * as yup from "yup";
import ResultModal from "../modals/ResultModal";

const automaticCompressionInputSchema = yup.object({
  modelName: yup
    .string()
    .matches(
      /^[0-9a-zA-Z_]+$/,
      "Only alphabets, numbers, and underscores can be entered in the model name."
    )
    .required("Please enter a model name"),
  memo: yup.string(),
  baseModel: yup.string().required("Please select a base model to compress"),
  ratio: yup
    .number()
    .typeError("Ratio is a number greater than 0 and less than or equal to 1")
    .moreThan(0, "Ratio is a number greater than 0 and less than or equal to 1")
    .max(1, "Ratio is a number greater than 0 and less than or equal to 1")
    .required("Please enter a ratio to compress"),
});

export default function AutomaticCompressionPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const GetModels = useQuery(["parentsModels"], getParentsModels);
  const PostAutoCompress = useMutation(postAutoCompress, {
    onSuccess: ({ data }) => {
      queryClient.resetQueries(["user"]);
      setShow({ resultModal: true });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(automaticCompressionInputSchema),
    defaultValues: {
      modelName: "",
      memo: "",
      baseModel: "",
      ratio: 0.5,
    },
  });
  const [show, setShow] = useState({
    resultModal: false,
  });
  const baseModel = watch("baseModel");
  const ratio = watch("ratio");
  const [ratioText, setRatioText] = useState(0.5);
  useEffect(() => {
    setRatioText(ratio);
  }, [ratio]);
  const handleChangeRatioText = (e) => {
    const value = e.target.value.replace(/[^0-9.-]/g, "");
    setRatioText(value);
    if (!value.endsWith(".") && Number(e.target.value) >= 0)
      setValue("ratio", Number(value));
  };
  const handleValidationError = (error) => {
    Object.entries(error).every(([name, { message, type, ref }]) => {
      alert(message);
      return false;
    });
  };
  const handleClickCompress = (data) => {
    if (
      !window.confirm(
        "Do you want to compress?\n25 credits will be used.\nCompression may take about 5 minutes."
      )
    )
      return;
    const { modelName, memo, baseModel, ratio } = data;
    PostAutoCompress.mutate({
      modelId: baseModel,
      description: memo,
      modelName: modelName,
      recommendationRatio: ratio,
    });
  };
  const handleClickCancel = () => {
    reset();
    PostAutoCompress.reset();
    navigate(-1);
  };
  useEffect(() => {
    const modelId = searchParams.get("model");
    if (modelId && GetModels?.data?.data) {
      const model = GetModels?.data?.data.find(
        (model) => model.model_id === modelId
      );
      if (model) {
        setValue("baseModel", modelId);
      }
    }
  }, [GetModels.isSuccess]);
  const handleClickReset = () => {
    reset();
    PostAutoCompress.reset();
  };
  const handleClickToggleShow = (e) => {
    const name = e.currentTarget.dataset.name;
    setShow({ ...show, [name]: !show[name] });
  };
  return (
    <Paper>
      <form onSubmit={handleSubmit(handleClickCompress, handleValidationError)}>
        <div className="p-4 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <PhraseTitle>Model info</PhraseTitle>
            <div className="flex flex-col gap-1">
              <LabelTitle>Model name *</LabelTitle>
              <TextInput
                {...register("modelName")}
                disabled={
                  PostAutoCompress.isSuccess || PostAutoCompress.isLoading
                }
              />
            </div>
            <div className="flex flex-col gap-1">
              <LabelTitle>Memo</LabelTitle>
              <TextArea
                {...register("memo")}
                disabled={
                  PostAutoCompress.isSuccess || PostAutoCompress.isLoading
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <PhraseTitle>Select a base model</PhraseTitle>
            <div>
              <LabelTitle>Base model *</LabelTitle>
              <div className="flex items-center gap-1">
                <Select
                  {...register("baseModel")}
                  disabled={
                    GetModels.isLoading ||
                    PostAutoCompress.isLoading ||
                    PostAutoCompress.isSuccess
                  }
                >
                  {GetModels.isLoading && (
                    <option value="">Loading Models..</option>
                  )}
                  {GetModels.isSuccess && (
                    <option value="">Please select model</option>
                  )}
                  {GetModels.isSuccess &&
                    GetModels.data.data.map((model) => {
                      return (
                        <option key={model.model_id} value={model.model_id}>
                          {model.model_name}
                        </option>
                      );
                    })}
                </Select>
                {baseModel &&
                  (GetModels.data.data.find(
                    (model) => model.model_id === baseModel
                  ).origin_from === "custom" ? (
                    <Badge color="gray">Custom</Badge>
                  ) : (
                    <Badge color="mint">NetsPresso</Badge>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <PhraseTitle>Compression options</PhraseTitle>
            <div className="flex flex-col gap-1">
              <LabelTitle>Compression ratio *</LabelTitle>
              <div className="flex items-center gap-1">
                <div className="w-16">
                  <TextInput
                    value={ratioText}
                    onChange={handleChangeRatioText}
                    disabled={
                      PostAutoCompress.isLoading || PostAutoCompress.isSuccess
                    }
                  />
                </div>
                <div className="w-80">
                  <Controller
                    name="ratio"
                    control={control}
                    render={({ field }) => (
                      <Slider
                        {...field}
                        defaultValue={0.5}
                        min={0.01}
                        max={1}
                        step={0.01}
                        disabled={
                          PostAutoCompress.isLoading ||
                          PostAutoCompress.isSuccess
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <Alert severity="info">
                <ul className="list-disc list-inside">
                  <li>{`0 < Ratio (float) ≤ 1`}</li>
                  <li>{`Compressed model needs to be retrained to recover the accuracy.`}</li>
                </ul>
              </Alert>
            </div>
          </div>
          <div className="flex justify-end items-center gap-2">
            {PostAutoCompress.isLoading && <Spinner />}
            <Button
              color="red"
              disabled={PostAutoCompress.isLoading}
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <Button
              color="red"
              disabled={PostAutoCompress.isLoading}
              onClick={handleClickReset}
            >
              Reset
            </Button>
            {!PostAutoCompress.isSuccess && (
              <Button type="submit" disabled={PostAutoCompress.isLoading}>
                Compress
              </Button>
            )}
            {PostAutoCompress.isSuccess && (
              <Button data-name="resultModal" onClick={handleClickToggleShow}>
                Show Result
              </Button>
            )}
            {PostAutoCompress.isSuccess && (
              <ResultModal
                show={show}
                beforeModel={GetModels.data.data.find(
                  (model) => model.model_id === getValues("baseModel")
                )}
                afterModel={PostAutoCompress.data.data}
                handleClickToggleShow={handleClickToggleShow}
              />
            )}
          </div>
        </div>
      </form>
    </Paper>
  );
}
