import keras_saved_model_image from "../assets/images/keras_saved_model.png";
import tucker_decomposition_image from "../assets/images/tucker_decomposition.png";
import singular_value_decomposition from "../assets/images/singular_value_decomposition.png";
import cp_decomposition_image from "../assets/images/cp_decomposition.png";
import calibration_ratio_image from "../assets/images/calibration_ratio.png";
import pruning_gm_image from "../assets/images/pruning_gm.png";
import pruning_nn_image from "../assets/images/pruning_nn.png";
import pruning_by_index_image from "../assets/images/pruning_by_index.png";
import pruning_l2_image from "../assets/images/pruning_l2.png";

const tooltip = {
  upload_framework_keras: (
    <div>Tensorflow version 2.2.x ~ 2.5.x supported only.</div>
  ),
  upload_framework_torch: <div>PyTorch version 1.10.x supported only.</div>,
  upload_type_keras: (
    <div>Custom layer must not be included in Keras h5 file.</div>
  ),
  upload_type_keras_saved_model: (
    <div>
      <div>
        If the custom layer is included in the model, it should be Keras saved
        model and compressed in .zip to be uploaded.
      </div>
      <img src={keras_saved_model_image} alt="" style={{ width: 300 }} />
    </div>
  ),
  upload_type_onnx: <div>ONNX version 1.10.x supported only.</div>,
  compress_method_tucker_decomposition: (
    <div>
      <p>Approximating the original filters by Tucker decomposition method.</p>
      <p>
        {" "}
        This method decomposes the convolution with a 4D kernel tensor into two
        factor matrices and one small core tensor.
      </p>
      <img
        src={tucker_decomposition_image}
        alt=""
        className="w-full max-w-2xl"
      />
      <br />
      <ul className="list-disc list-inside">
        <li>In Channel : The number of input channel in each layer.</li>
        <li>Out Channel : The number of output channel in each layer.</li>
        <li>
          In Rank : The number of input channel of core tensor that represent
          relation level of low-rank factor matrix.
        </li>
        <li>
          Out Rank : The number of output channel of core tensor that represent
          relation level of low-rank factor matrix.
        </li>
      </ul>
      <br />
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-filter-decomposition#supported-method"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
      <br />
    </div>
  ),
  compress_method_singular_value_decomposition: (
    <div>
      <p>
        Approximating the original filters by Singular value decomposition
        method.
        <br />
        This method decomposes the pointwise convolution or fully-connected
        layer into two pointwise or fully-connected layers.
      </p>
      <br />
      <img
        src={singular_value_decomposition}
        alt=""
        className="w-full max-w-2xl"
      />
      <br />
      <ul className="list-disc list-inside">
        <li>In Channel: The number of input channels of the target layer.</li>
        <li>Out Channel: The number of output channels of the target layer.</li>
        <li>Rank: The condition number of weight matrix W.</li>
      </ul>
      <br />
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-filter-decomposition#supported-method"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
      <br />
    </div>
  ),
  compress_method_cp_decomposition: (
    <div>
      <p>Approximating the original filters by CP decomposition method.</p>
      <p>
        This method approximates the convolution with a 4D kernel tensor by the
        sequence of four convolutions with small 2D kernel tensors.
      </p>
      <img src={cp_decomposition_image} alt="" className="w-full max-w-2xl" />
      <br />
      <ul className="list-disc list-inside">
        <li>In Channel : The number of input channel in each layer.</li>
        <li>Out Channel : The number of output channel in each layer.</li>
        <li>
          Rank : A sum of N-way outer products of rank-one tensor for estimating
          original convolution filter.
        </li>
      </ul>
      <br />
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-filter-decomposition#supported-method"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
      <br />
    </div>
  ),
  compress_method_structured_pruning: (
    <div>
      <div>
        The structured pruning provides channel-wise pruning to induce actual
        speed up of model.
      </div>
      <div>
        <a
          href="https://docs.netspresso.ai/docs/pruning#what-is-pruning"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </div>
    </div>
  ),
  compress_method_pruning_by_index: (
    <div>
      <p>
        This function prunes the chosen filters of each layer through the index
        without certain criteria.
      </p>
      <p>You can apply your own criteria to prune the model.</p>
      <p>
        If the selected filters are redundant or less important, it will return
        a better performing model.
      </p>
      <br />
      <p>
        <img src={pruning_by_index_image} alt="" className="w-full max-w-md" />
      </p>
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-structured-pruning#supported-functions"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
    </div>
  ),
  compress_method_pruning_by_criteria: (
    <div>
      <div>
        This function prunes the unnecessary channels through specific criteria,
        such as L2-Norm.
      </div>
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-structured-pruning#supported-functions"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
    </div>
  ),
  compress_method_l2_norm: (
    <div>
      <p>
        Difference of each pruning method is about{" "}
        <b>measuring importance of filters</b> in each layer. Filters in each
        layer will be automatically pruned based on certain criteria.
      </p>
      <p>
        L2-Norm is used to represent the importance of the corresponding filter.
        In other words, this method prunes filters based on the{" "}
        <b>magnitude of weights.</b>
      </p>
      <p>
        <img src={pruning_l2_image} alt="" className="w-full max-w-2xl" />
      </p>
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-structured-pruning#supported-functions"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
    </div>
  ),
  compress_method_gm_pruning: (
    <div>
      <p>
        Difference of each pruning method is about{" "}
        <b>measuring importance of filters</b> in each layer. Filters in each
        layer will be automatically pruned based on certain criteria.
      </p>
      <p>
        <a
          href="https://arxiv.org/abs/1811.00250"
          target="_blank"
          rel="noreferrer"
        >
          Geometric Median
        </a>{" "}
        is used to measure the <b>redundancy</b> of the corresponding filter and
        remove redundant filters.
      </p>
      <p>
        <img src={pruning_gm_image} alt="" className="w-full max-w-2xl" />
      </p>
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-structured-pruning#supported-functions"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
    </div>
  ),
  compress_method_nuclear_norm: (
    <div>
      <p>
        Difference of each pruning method is about{" "}
        <b>measuring importance of filters</b> in each layer. Filters in each
        layer will be automatically pruned based on certain criteria.
      </p>
      <p>
        The{" "}
        <a
          href="https://arxiv.org/abs/2103.10858"
          target="_blank"
          rel="noreferrer"
        >
          Nuclear Norm
        </a>{" "}
        is the sum of the singular values representing the <b>energy</b>. It
        computes the nuclear norm on the <b>feature map</b> to determine the
        filter's relevance. For this reason, a portion of the dataset is needed.
      </p>
      <p>
        <img src={pruning_nn_image} alt="" className="w-full max-w-2xl" />
      </p>
      <br />
      <p>
        Prune the filter by calculating the of the corresponding output feature
        map. A portion of the dataset is necessary to measure the importance.
        <br />
        (#images x H x W x RGB dataset with .npy format with 15MB limited)
      </p>
      <p>
        <a
          href="https://docs.netspresso.ai/docs/mc-structured-pruning#supported-functions"
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      </p>
    </div>
  ),
  compress_config_layer_name: (
    <div>The name of an available layer to compress.</div>
  ),
  compress_config_in_channel: (
    <div>The number of input channel in each layer.</div>
  ),
  compress_config_out_channel: (
    <div>The number of output channel in each layer.</div>
  ),
  compress_config_in_rank: (
    <div>
      <div>
        The number of input channel of core tensor that represent relation level
        of low-rank factor matrix.
      </div>
      <div>{"0 < rank(integer) ≤ full rank of In Channel"}</div>
    </div>
  ),
  compress_config_out_rank: (
    <div>
      <div>
        The number of output channel of core tensor that represent relation
        level of low-rank factor matrix.
      </div>
      <div>{"0 < rank(integer) ≤ full rank of Out Channel"}</div>
    </div>
  ),
  compress_config_rank: (
    <div>
      <div>
        A sum of N-way outer products of rank-one tensor for estimating original
        convolution filter.
      </div>
      <div>{"0 < rank(integer) ≤ min(full rank of in & out)"}</div>
    </div>
  ),
  compress_config_channels: (
    <div>
      <div>Index of channel to prune in each layer.</div>
      <div>{"0 ≤ index(integer) < Out Channel\n(e.g. 0,1-5,10-20)"}</div>
    </div>
  ),
  compress_config_ratio: (
    <div>
      <div>
        Remove corresponding amounts of the filters. (e.g. 0.2 removes 20% of
        the filters in each layer)
      </div>
      <div>{"0.0 < ratio(float) ≤ 1.0"}</div>
    </div>
  ),
  compress_config_l2norm_policy: (
    <div>
      Policy to handle the element-wise operation. You have to select one of
      follows: (average, intersection, union)
    </div>
  ),
  compress_config_l2norm_policy_average: (
    <div>
      The average of each filter's criterion value represents the importance of
      the corresponding filter.
    </div>
  ),
  compress_config_l2norm_policy_intersection: (
    <div>
      Pruning using intersection index of selected convolution filter in each
      layer.
    </div>
  ),
  compress_config_l2norm_policy_union: (
    <div>
      Pruning using union index of selected convolution filter index in each
      layer.
    </div>
  ),
  compress_config_l2norm_reverse: (
    <div>
      If the reverse is True, the filters with a small criterion (Here, l2)
      value will be preserved and a large l2 value will be pruned.
    </div>
  ),
  compress_config_l2norm_layer_norm: (
    <div>
      Selected criterion values will be additionally normalized by layer for the
      layer-wise comparison.
    </div>
  ),
  compress_recommendation_method_filter_decomposition: (
    <div>
      Recommendation calculates the ranks based on global analytic variational
      Bayesian solution.
    </div>
  ),
  compress_recommendation_method_structured_pruning: (
    <div>
      Global pruning method to consider the layer-wise importance for structured
      pruning.
    </div>
  ),
  compress_recommendation_calibration_ratio: (
    <div>
      <div>
        This function control compression level of model if the result of
        recommendation doesn't meet the compression level user wants.
      </div>
      <div>
        Remained rank add or subtract (removed rank x calibration ratio)
        according to calibration ratio range.
      </div>
      <img src={calibration_ratio_image} alt="" style={{ width: "100%" }} />
    </div>
  ),
  compress_recommendation_pruning_ratio: (
    <div>
      Remove corresponding amounts of the filters considering layer-wise
      importance. (e.g. 0.2 removes 20% of the filters in a model)
    </div>
  ),
};

export default tooltip;
