import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const RadioButton = ({
  label,
  value,
  name,
  checked,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <label>
      <input
        {...props}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        type="radio"
        className="hidden peer"
      />
      <div
        className="w-[270px] rounded px-3 py-2 bg-gray-200 
    flex items-center justify-between
peer-checked:bg-blue-600 peer-checked:text-white 
peer-disabled:bg-gray-100 peer-disabled:text-gray-300
peer-checked:peer-disabled:bg-blue-300 peer-checked:peer-disabled:text-white
hover:cursor-pointer"
      >
        <div>{label}</div>
        {checked && <CheckCircleOutlineIcon />}
      </div>
    </label>
  );
};

export default RadioButton;
