import React from "react";

const Footer = () => {
  return (
    <div className="h-12 p-3 w-full flex items-center justify-between text-xs">
      <div className="text-black">
        Copyright @ Nota Inc. All rights reserved.
      </div>
      <div>
        <a
          href="https://www.nota.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          nota.ai
        </a>
        <span>{" | "}</span>
        <a
          href="https://www.netspresso.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          netspresso.ai
        </a>
        <span>{" | "}</span>
        <a
          href="mailto:netspresso@nota.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default React.memo(Footer);
