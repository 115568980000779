import CloseIcon from "@mui/icons-material/Close";
import {
  getModelShapeString,
  objectToArray,
  snakeCaseToTitleCase,
} from "src/library/utils";

export default function NetworkGraphNodeDetail({
  selectedLayer,
  setSelectedLayer,
}) {
  return (
    <div className="absolute top-0 right-0 w-80 max-h-[calc(100%_-_20px)] m-2 p-2 bg-white bg-opacity-90 border overflow-auto z-10">
      <div className="flex justify-between">
        <div className="text-xl">
          <b>{selectedLayer?.values?.name}</b>
        </div>
        <button
          onClick={() => setSelectedLayer()}
          className="focus:outline-none"
        >
          <CloseIcon />
        </button>
      </div>
      <br />
      <table className="w-full table text-xs">
        <tbody>
          {objectToArray(selectedLayer?.values).map((value) => {
            const [k, v] = value;
            return (
              <tr key={k} className="">
                <td>
                  <b>{snakeCaseToTitleCase(k)}</b>
                </td>
                <td>
                  {Array.isArray(v)
                    ? getModelShapeString(v, "N")
                    : v === null
                    ? "-"
                    : typeof v === "object"
                    ? JSON.stringify(v)
                    : String(v)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
