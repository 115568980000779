import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PhraseTitle from "src/components/PhraseTitle";
import Title from "src/components/Title";
import Button from "../../../components/Button";

const ResultModal = ({
  show,
  beforeModel,
  afterModel,
  handleClickToggleShow,
}) => {
  const handleClickCSV = () => {
    const csv = `\uFEFFcategory,original model,compressed model
FLOPs (M),${beforeModel.spec.flops},${afterModel.spec.flops}
layer count,${beforeModel.spec.layer_count},${afterModel.spec.layer_count}
model size (MB),${beforeModel.spec.model_size},${afterModel.spec.model_size}
non trainable parameters (M),${beforeModel.spec.non_trainable_parameters},${afterModel.spec.non_trainable_parameters}
trainable parameters (M),${beforeModel.spec.trainable_parameters},${afterModel.spec.trainable_parameters}
`.trim();
    const csvFile = new Blob([csv], { type: "text/csv" });
    const fileName = "compression_report.csv";
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(csvFile);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleClickRetrain = (modelId) => {
    window.location.assign(
      `${process.env.REACT_APP_SEARCHER_URL}/projects/retraining/model/${afterModel.model_id}`
    );
  };
  return (
    <Dialog fullWidth maxWidth="md" open={show.resultModal}>
      <DialogTitle>
        <div className="flex justify-between">
          <Title>Compression Result</Title>
          {/* <button
            data-name="resultModal"
            onClick={handleClickToggleShow}
            className="focus:outline-none"
          >
            <CloseIcon />
          </button> */}
        </div>
      </DialogTitle>
      <DialogContent className="overflow-y-scroll">
        <div>
          <PhraseTitle>Compression result details</PhraseTitle>
          <div className="grid grid-cols-2 overflow-hidden">
            <div>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={[
                    {
                      name: "FLOPs(M)",
                      "original model": beforeModel.spec.flops,
                      "compressed model": afterModel.spec.flops,
                      "compressed model label": `${afterModel.spec.flops} (${(
                        beforeModel.spec.flops / afterModel.spec.flops
                      ).toFixed(2)}x)`,
                    },
                  ]}
                  margin={{ top: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar
                    dataKey="original model"
                    fill="#8884d8"
                    isAnimationActive={false}
                  >
                    <LabelList dataKey="original model" position="top" />
                  </Bar>
                  <Bar
                    dataKey="compressed model"
                    fill="#82ca9d"
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="compressed model label"
                      position="top"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={[
                    {
                      name: "Model Size(MB)",
                      "original model": beforeModel.spec.model_size,
                      "compressed model": afterModel.spec.model_size,
                      "compressed model label": `${
                        afterModel.spec.model_size
                      } (${(
                        beforeModel.spec.model_size / afterModel.spec.model_size
                      ).toFixed(2)}x)`,
                    },
                  ]}
                  margin={{ top: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar
                    dataKey="original model"
                    fill="#8884d8"
                    isAnimationActive={false}
                  >
                    <LabelList dataKey="original model" position="top" />
                  </Bar>
                  <Bar
                    dataKey="compressed model"
                    fill="#82ca9d"
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="compressed model label"
                      position="top"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={[
                    {
                      name: "Trainable Parameters(M)",
                      "original model": beforeModel.spec.trainable_parameters,
                      "compressed model": afterModel.spec.trainable_parameters,
                      "compressed model label": `${
                        afterModel.spec.trainable_parameters
                      } (${(
                        beforeModel.spec.trainable_parameters /
                        afterModel.spec.trainable_parameters
                      ).toFixed(2)}x)`,
                    },
                  ]}
                  margin={{ top: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="original model"
                    fill="#8884d8"
                    isAnimationActive={false}
                  >
                    <LabelList dataKey="original model" position="top" />
                  </Bar>
                  <Bar
                    dataKey="compressed model"
                    fill="#82ca9d"
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="compressed model label"
                      position="top"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={[
                    {
                      name: "Non Trainable Parameters(M)",
                      "original model":
                        beforeModel.spec.non_trainable_parameters,
                      "compressed model":
                        afterModel.spec.non_trainable_parameters,
                      "compressed model label": `${
                        afterModel.spec.non_trainable_parameters
                      } (${
                        afterModel.spec.non_trainable_parameters !== 0
                          ? (
                              beforeModel.spec.non_trainable_parameters /
                              afterModel.spec.non_trainable_parameters
                            ).toFixed(2)
                          : "1.00"
                      }x)`,
                    },
                  ]}
                  margin={{ top: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="original model"
                    fill="#8884d8"
                    isAnimationActive={false}
                  >
                    <LabelList dataKey="original model" position="top" />
                  </Bar>
                  <Bar
                    dataKey="compressed model"
                    fill="#82ca9d"
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="compressed model label"
                      position="top"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <br />
        <DialogActions>
          <div className="flex justify-end gap-3">
            <Button onClick={handleClickCSV}>Download CSV</Button>
          </div>
        </DialogActions>
        {afterModel.status.is_retrainable && (
          <>
            <div>
              <PhraseTitle>Do you want to retrain the model?</PhraseTitle>
              <div>
                Compressed model needs to be retrained to recover the accuracy.
                Since the model is created with NetsPresso, you can create a
                project to retrain the model immediately.
              </div>
            </div>
            <DialogActions>
              <div className="flex justify-end gap-3">
                <Button
                  color="gray"
                  data-name="resultModal"
                  onClick={handleClickToggleShow}
                >
                  Close
                </Button>
                <Button onClick={handleClickRetrain}>Do Retrain</Button>
              </div>
            </DialogActions>
          </>
        )}
        {!afterModel.status.is_retrainable && (
          <>
            <div>
              <PhraseTitle>Compressed model needs to be retrained.</PhraseTitle>
              <div>
                We recommend you retrain the model to recover the accuracy.
                Please see our{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/Nota-NetsPresso/NetsPresso-Model-Compressor-ModelZoo/tree/main/best_practices"
                >
                  Best Practices
                </a>{" "}
                to see the retraining process. Please use the same dataset that
                was used to train the original model. If you have any troubles
                with retraining, please leave a feedback on our Github
                Discussion or contact to netspresso@nota.ai.
              </div>
            </div>
            <DialogActions>
              <div className="flex justify-end gap-3">
                <Button
                  color="gray"
                  data-name="resultModal"
                  onClick={handleClickToggleShow}
                >
                  Close
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </DialogContent>
      {/* <DialogActions>
        <div className="flex justify-end gap-3">
          <Button
            color="gray"
            data-name="resultModal"
            onClick={handleClickToggleShow}
          >
            Close
          </Button>
        </div>
      </DialogActions> */}
    </Dialog>
  );
};

export default React.memo(ResultModal);
