import React from "react";

const Select = React.forwardRef((props, ref) => {
  return (
    <select
      {...props}
      ref={ref}
      className="max-w-full h-[42px] p-2 rounded text-black border border-gray-300 disabled:bg-gray-200"
    />
  );
});

export default Select;
