import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Alert, Collapse } from "@mui/material";
import { useContext, useMemo } from "react";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import FileInput from "src/components/FileInput";
import LabelTitle from "src/components/LabelTitle";
import Paper from "src/components/Paper";
import PhraseTitle from "src/components/PhraseTitle";
import RadioButton from "src/components/RadioButton";
import Select from "src/components/Select";
import Spinner from "src/components/Spinner";
import TextArea from "src/components/TextArea";
import TextInput from "src/components/TextInput";
import Title from "src/components/Title";
import tooltip from "src/constants/tooltip";
import {
  getCompressionMethodLabel,
  getModelShapeString,
} from "src/library/utils";
import { AdvancedCompressionContext } from "./AdvancedCompressionProvider";

export default function Phase1() {
  const {
    show,
    input,
    models,
    model,
    compression,
    layers,
    compressedModel,
    GetModels,
    PostCompression,
    PostDataset,
    PostRecommendation,
    PutCompression,
    GetCompressedModel,
    GetUser,
    handleChangeInput,
    handleChangeBaseModel,
    handleChangeCompressionMethod,
    handleClickToggleShow,
    handleChangeDatasetFile,
    handleClickResetAll,
    handleClickSelectMethod,
    handleClickCancel,
  } = useContext(AdvancedCompressionContext);
  const isDisabled = useMemo(() => {
    if (PostCompression.isLoading || PostDataset.isLoading) {
      return true;
    } else if (PostCompression.isSuccess) {
      return true;
    } else {
      return false;
    }
  }, [PostCompression, PostDataset]);
  return (
    <Paper>
      <div
        className="p-3 flex items-center justify-between cursor-pointer"
        data-name="phase1"
        onClick={handleClickToggleShow}
      >
        <div className="flex items-center justify-center">
          <Title>1. Select model & method</Title>
          <div className="pl-2 flex items-center gap-2">
            {model.model_name && <Badge>Base Model: {model.model_name}</Badge>}
            {input.compressionMethod && (
              <Badge>
                Compression Method:&nbsp;
                {getCompressionMethodLabel(input.compressionMethod)}
              </Badge>
            )}
          </div>
        </div>
        <div>{show.phase1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
      </div>
      <Collapse orientation="vertical" in={show.phase1}>
        <div className="p-3 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <PhraseTitle>Model info</PhraseTitle>
            <div>
              <LabelTitle>Model name *</LabelTitle>
              <TextInput
                name="modelName"
                value={input.modelName}
                onChange={handleChangeInput}
                disabled={isDisabled}
              />
            </div>
            <div>
              <LabelTitle>Memo</LabelTitle>
              <TextArea
                name="description"
                value={input.description}
                onChange={handleChangeInput}
                disabled={isDisabled}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <PhraseTitle>Select a base model</PhraseTitle>
            <div>
              <LabelTitle>Base model *</LabelTitle>
              <div className="flex items-center gap-1">
                <Select
                  name="modelId"
                  value={input.modelId}
                  onChange={handleChangeBaseModel}
                  disabled={!GetModels.isSuccess || isDisabled}
                >
                  <option value="">
                    {GetModels.isLoading
                      ? "Loading models..."
                      : "Please select model"}
                  </option>
                  {GetModels.isSuccess &&
                    models.map((model) => {
                      return (
                        <option key={model.model_id} value={model.model_id}>
                          {model.model_name}
                        </option>
                      );
                    })}
                </Select>
                {model.model_id &&
                  (model.origin_from === "custom" ? (
                    <Badge color="gray">Custom</Badge>
                  ) : (
                    <Badge color="mint">NetsPresso</Badge>
                  ))}
              </div>
            </div>
          </div>

          <div className="min-h-[500px] flex flex-col gap-2">
            <PhraseTitle>Method</PhraseTitle>
            <div>
              <LabelTitle>Method *</LabelTitle>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <div className="text-xs">Structured Pruning (Criteria)</div>
                    <RadioButton
                      value="PR_L2"
                      name="compressionMethod"
                      checked={input.compressionMethod === "PR_L2"}
                      onChange={handleChangeCompressionMethod}
                      disabled={isDisabled}
                      label={getCompressionMethodLabel("PR_L2")}
                    />
                    <RadioButton
                      value="PR_GM"
                      name="compressionMethod"
                      checked={input.compressionMethod === "PR_GM"}
                      onChange={handleChangeCompressionMethod}
                      disabled={isDisabled}
                      label={getCompressionMethodLabel("PR_GM")}
                    />
                    <RadioButton
                      value="PR_NN"
                      name="compressionMethod"
                      checked={input.compressionMethod === "PR_NN"}
                      onChange={handleChangeCompressionMethod}
                      // 220829 BM onnx, pytorch disabled 요청
                      disabled={
                        isDisabled ||
                        model.framework === "onnx" ||
                        model.framework === "pytorch"
                      }
                      label={getCompressionMethodLabel("PR_NN")}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="text-xs">Structured Pruning (Index)</div>
                    <RadioButton
                      value="PR_ID"
                      name="compressionMethod"
                      checked={input.compressionMethod === "PR_ID"}
                      onChange={handleChangeCompressionMethod}
                      disabled={isDisabled}
                      label={getCompressionMethodLabel("PR_ID")}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="text-xs">Filter Decomposition</div>
                    <RadioButton
                      value="FD_TK"
                      name="compressionMethod"
                      checked={input.compressionMethod === "FD_TK"}
                      onChange={handleChangeCompressionMethod}
                      disabled={isDisabled}
                      label={getCompressionMethodLabel("FD_TK")}
                    />
                    <RadioButton
                      value="FD_SVD"
                      name="compressionMethod"
                      checked={input.compressionMethod === "FD_SVD"}
                      onChange={handleChangeCompressionMethod}
                      disabled={isDisabled}
                      label={getCompressionMethodLabel("FD_SVD")}
                    />
                    <RadioButton
                      value="FD_CP"
                      name="compressionMethod"
                      checked={input.compressionMethod === "FD_CP"}
                      onChange={handleChangeCompressionMethod}
                      disabled={isDisabled}
                      label={getCompressionMethodLabel("FD_CP")}
                    />
                  </div>
                </div>
                <div className="flex-1 ml-2 p-4 rounded border border-gray-300">
                  {input.compressionMethod === "" && (
                    <div className="w-full h-full flex flex-col justify-center items-center text-center font-semibold">
                      <ErrorOutlineIcon fontSize="large" />
                      <br />
                      Please choose one of the following compression methods
                      <br />
                      to apply to the model selected left.
                    </div>
                  )}
                  {input.compressionMethod === "FD_TK" && (
                    <div className="h-full flex flex-col justify-between">
                      <div>{tooltip.compress_method_tucker_decomposition}</div>
                      <div>
                        <Alert severity="info">
                          Using filter decomposition may reduce or increase the
                          model size depending on your configuration.
                        </Alert>
                      </div>
                    </div>
                  )}
                  {input.compressionMethod === "FD_SVD" && (
                    <div className="h-full flex flex-col justify-between">
                      <div>
                        {tooltip.compress_method_singular_value_decomposition}
                      </div>
                      <div>
                        <Alert severity="info">
                          Using filter decomposition may reduce or increase the
                          model size depending on your configuration.
                        </Alert>
                      </div>
                    </div>
                  )}
                  {input.compressionMethod === "FD_CP" && (
                    <div className="h-full flex flex-col justify-between">
                      <div>{tooltip.compress_method_cp_decomposition}</div>
                      <div>
                        <Alert severity="info">
                          Using filter decomposition may reduce or increase the
                          model size depending on your configuration.
                        </Alert>
                      </div>
                    </div>
                  )}
                  {input.compressionMethod === "PR_L2" &&
                    tooltip.compress_method_l2_norm}
                  {input.compressionMethod === "PR_GM" &&
                    tooltip.compress_method_gm_pruning}
                  {input.compressionMethod === "PR_NN" && (
                    <div className="h-full flex flex-col justify-between">
                      <div>{tooltip.compress_method_nuclear_norm}</div>
                      {model.framework === "torch" ? (
                        <Alert severity="error">
                          Nuclear Norm Pruning is not available in pytorch
                          model&nbsp;&#40;.onnx&#41;
                        </Alert>
                      ) : (
                        <div>
                          <Alert severity="info">
                            This method requires a dataset&#40;
                            <b>.npy file format with 15MB limited</b>&#41;
                            <br />
                            {model?.input_shape?.length && (
                              <span>
                                Model Input Shape: Numpy array with shape&nbsp;
                                <b>
                                  {getModelShapeString(model.input_shape, "N")}
                                </b>
                              </span>
                            )}
                            {!model?.input_shape?.length && (
                              <span>Model Input Shape: unknown</span>
                            )}
                          </Alert>
                          <br />
                          <FileInput
                            file={input.datasetFile}
                            drag={false}
                            buttonText={"Select File"}
                            inputText="Please Select a Dataset File"
                            dragText={"(Less than 600MB is available)"}
                            onChange={handleChangeDatasetFile}
                            disabled={isDisabled}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {input.compressionMethod === "PR_ID" &&
                    tooltip.compress_method_pruning_by_index}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-3">
            {(PostCompression.isLoading || PostDataset.isLoading) && (
              <Spinner />
            )}
            <Button
              color="red"
              onClick={handleClickCancel}
              disabled={PostCompression.isLoading || PostDataset.isLoading}
            >
              Cancel
            </Button>
            <Button
              color="red"
              onClick={handleClickResetAll}
              disabled={PostCompression.isLoading || PostDataset.isLoading}
            >
              Reset
            </Button>
            <Button onClick={handleClickSelectMethod} disabled={isDisabled}>
              Select Method
            </Button>
          </div>
        </div>
      </Collapse>
    </Paper>
  );
}
