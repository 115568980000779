import { Menu } from "@headlessui/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CompressIcon from "@mui/icons-material/Compress";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";
import classNames from "classnames";
import { Fragment, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Badge from "src/components/Badge";
import IconButton from "src/components/IconButton";
import Spinner from "src/components/Spinner";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  deleteModel,
  getChildrenModels,
  postModelDownloadURL,
} from "src/library/apis";
import { Tooltip } from "@mui/material";
import {
  getDeviceLabel,
  getFrameworkLabel,
  getLocalTimeString,
} from "src/library/utils";

const getTaskLabel = (taskName) => {
  const taskName2TaskLabel = {
    image_classification: "Image Classification",
    object_detection: "Object Detection",
    image_segmentation: "Image Segmentation",
    semantic_segmentation: "Semantic Segmentation",
    instance_segmentation: "Instance Segmentation",
    panoptic_segmentation: "Panoptic Segmentation",
    pose_estimation: "Pose Estimation",
    super_resolution: "Super Resolution",
    other: "Other",
  };
  return taskName2TaskLabel[taskName];
};

export default function TableRow({ model }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const GetChildren = useQuery(
    ["childrenModel", model.model_id],
    () => getChildrenModels({ modelId: model.model_id }),
    { enabled: open }
  );
  const PostModelDownloadURL = useMutation(
    (model) => postModelDownloadURL(model),
    {
      onSuccess: ({ data }) => {
        const link = document.createElement("a");
        // link.download = fileName;
        // link.href = window.URL.createObjectURL(data.url);
        link.href = data.url;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      onError: () => {},
    }
  );
  const DeleteParentsModel = useMutation((model) => deleteModel(model), {
    onSuccess: ({ data }) => {
      alert("Model deleted successfully");
      queryClient.refetchQueries(["parentsModels"]);
    },
    onError: () => {},
  });
  const DeleteChildrenModel = useMutation((model) => deleteModel(model), {
    onSuccess: ({ data }) => {
      alert("Model deleted successfully");
      queryClient.refetchQueries(["childrenModel", model.model_id]);
    },
    onError: () => {},
  });
  const handleClickOpenChildren = () => {
    setOpen(true);
    queryClient.refetchQueries(["childrenModel", model.model_id]);
  };
  const handleClickCloseChildren = () => {
    setOpen(false);
  };
  const handleClickCompress = (modelId) => {
    navigate(`/compression?model=${modelId}`);
  };
  const handleClickConvert = (modelId) => {
    window.location.assign(
      `${process.env.REACT_APP_SEARCHER_URL}/convert/model/${modelId}`
    );
  };
  const handleClickRetrain = (modelId) => {
    window.location.assign(
      `${process.env.REACT_APP_SEARCHER_URL}/projects/retraining/model/${modelId}`
    );
  };
  const handleClickDownload = (modelId) => {
    PostModelDownloadURL.mutate({ modelId: modelId });
  };
  const handleClickPackage = (modelId) => {
    window.location.assign(
      `${process.env.REACT_APP_SEARCHER_URL}/package/model/${modelId}`
    );
  };
  const handleClickDeleteParentsModel = (modelId) => {
    if (window.confirm("Are you sure you want to delete this model?")) {
      DeleteParentsModel.mutate({ modelId: modelId });
    }
  };
  const handleClickDeleteChildrenModel = (modelId) => {
    if (window.confirm("Are you sure you want to delete this model?")) {
      DeleteChildrenModel.mutate({ modelId: modelId });
    }
  };
  const handleClickDetail = (modelId) => {
    navigate(`/models/${modelId}`);
  };
  return (
    <>
      <tr className="bg-[#ECF6FF] hover:bg-gray-100">
        <td className="text-center">
          {open ? (
            <IconButton onClick={handleClickCloseChildren} variant="outlined">
              <ArrowDropDownIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleClickOpenChildren} variant="contained">
              <ArrowRightIcon />
            </IconButton>
          )}
        </td>
        <td className="text-left">
          {model.origin_from === "custom" && <Badge color="gray">Custom</Badge>}
          {model.origin_from === "npms" && (
            <Badge color="mint">NetsPresso</Badge>
          )}
          <div className="text-md font-bold">{model.model_name}</div>
          <div className="text-xs text-gray-600">
            {`Created: ${getLocalTimeString(model.created_time)}`}
          </div>
        </td>
        <td>{getTaskLabel(model.task)}</td>
        <td className="text-left">
          {/* 유찬님 임시 삭제요청 */}
          {/* {getDeviceLabel(model.target_device)} */}
        </td>
        <td className="text-left max-w-[160px] overflow-auto">
          {/* 유찬님 임시 삭제요청 */}
          {!!model.metric.metric_unit && !!model.metric.metric_value && (
            <div>
              <div className="text-xs text-gray-600">
                {model.metric.metric_unit}
              </div>
              <div>{model.metric.metric_value}</div>
            </div>
          )}
        </td>
        <td className="text-left">
          {/* 유찬님 임시 삭제요청 */}
          {/* {model.devices[0].total_latency} */}
        </td>
        <td className="text-left">
          <div className="flex flex-nowrap justify-end items-center gap-1">
            <IconButton
              onClick={() => handleClickCompress(model.model_id)}
              disabled={!model.status.is_compressible}
            >
              <Tooltip arrow title={<div className="text-base">Compress</div>}>
                <CompressIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => handleClickConvert(model.model_id)}
              disabled={!model.status.is_convertible}
            >
              <Tooltip arrow title={<div className="text-base">Convert</div>}>
                <SyncIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => handleClickRetrain(model.model_id)}
              disabled={!model.status.is_retrainable}
            >
              <Tooltip arrow title={<div className="text-base">Retrain</div>}>
                <HubOutlinedIcon />
              </Tooltip>
            </IconButton>
            <Menu as="div" className="relative">
              <Menu.Button as={Fragment}>
                <IconButton
                  variant="contained"
                  disabled={!model.status.is_downloadable}
                >
                  <Tooltip
                    arrow
                    title={<div className="text-base">Download</div>}
                  >
                    <DownloadIcon />
                  </Tooltip>
                </IconButton>
              </Menu.Button>
              <Menu.Items className="origin-top-left absolute right-0 mt-2 w-[250px] rounded-md shadow-lg py-1 z-10 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleClickDownload(model.model_id)}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "w-full px-4 py-2 text-left text-sm text-gray-700"
                      )}
                    >
                      {getFrameworkLabel(model.framework)}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item disabled={!model.status.is_packageable}>
                  {({ disabled }) => (
                    <button
                      onClick={() => handleClickPackage(model.model_id)}
                      disabled={disabled}
                      className={
                        "w-full px-4 py-2 text-left text-sm text-[#1C77C3] hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400"
                      }
                    >
                      New package
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
            <IconButton
              onClick={() => handleClickDeleteParentsModel(model.model_id)}
            >
              <Tooltip arrow title={<div className="text-base">Delete</div>}>
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </div>
        </td>
        <td></td>
      </tr>
      {open && (
        <>
          {GetChildren.isLoading && (
            <tr>
              <td colSpan={8}>
                <div className="h-10 flex items-center justify-center">
                  <Spinner />
                </div>
              </td>
            </tr>
          )}
          {GetChildren.isSuccess && (
            <>
              {GetChildren.data.data.length === 0 && (
                <tr>
                  <td colSpan={8}>
                    <div className="h-10 flex items-center justify-center gap-2">
                      <ErrorOutlineIcon />
                      <div>No compressed model exists</div>
                    </div>
                  </td>
                </tr>
              )}
              {GetChildren.data.data.map((childModel) => (
                <tr key={childModel.model_id}>
                  <td className="text-center text-gray-600">
                    <CompressIcon />
                  </td>
                  <td className="">
                    <div className="text-md font-bold">
                      {childModel.model_name}
                    </div>
                    <div className="text-xs text-gray-600">
                      {`Created: ${getLocalTimeString(
                        childModel.created_time
                      )}`}
                    </div>
                  </td>
                  <td>{getTaskLabel(childModel.task)}</td>
                  <td className="text-left">
                    {getDeviceLabel(childModel.target_device)}
                  </td>
                  <td className="text-left max-w-[160px] overflow-auto">
                    {/* 유찬님 임시 삭제요청 */}
                    {!!childModel.metric.metric_unit &&
                      !!childModel.metric.metric_value && (
                        <div>
                          <div className="text-xs text-gray-600">
                            {childModel.metric.metric_unit}
                          </div>
                          <div>{childModel.metric.metric_value}</div>
                        </div>
                      )}
                  </td>
                  <td className="text-left">
                    {/* 유찬님 임시 삭제요청 */}
                    {childModel.devices[0].total_latency > 0
                      ? `${childModel.devices[0].total_latency} ms`
                      : childModel.devices[0].total_latency === 0
                      ? "Measuring..."
                      : childModel.devices[0].total_latency === -1
                      ? "Error"
                      : ""}
                  </td>
                  <td className="text-left">
                    <div className="flex flex-nowrap justify-end items-center gap-1">
                      <IconButton
                        onClick={() => handleClickRetrain(childModel.model_id)}
                        disabled={!childModel.status.is_retrainable}
                      >
                        <Tooltip
                          arrow
                          title={<div className="text-base">Retrain</div>}
                        >
                          <HubOutlinedIcon />
                        </Tooltip>
                      </IconButton>
                      <Menu as="div" className="relative">
                        <Menu.Button as={Fragment}>
                          <IconButton
                            variant="contained"
                            disabled={!childModel.status.is_downloadable}
                          >
                            <Tooltip
                              arrow
                              title={<div className="text-base">Download</div>}
                            >
                              <DownloadIcon />
                            </Tooltip>
                          </IconButton>
                        </Menu.Button>
                        <Menu.Items className="origin-top-left absolute right-0 mt-2 w-[250px] rounded-md shadow-lg py-1 z-10 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() =>
                                  handleClickDownload(childModel.model_id)
                                }
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "w-full px-4 py-2 text-left text-sm text-gray-700"
                                )}
                              >
                                {getFrameworkLabel(childModel.framework)}
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                      <IconButton
                        onClick={() =>
                          handleClickDeleteChildrenModel(childModel.model_id)
                        }
                      >
                        <Tooltip
                          arrow
                          title={<div className="text-base">Delete</div>}
                        >
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </div>
                  </td>
                  <td>
                    <IconButton
                      variant="ghost"
                      onClick={() => handleClickDetail(childModel.model_id)}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}
