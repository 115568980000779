import { useContext } from "react";
import { AdvancedCompressionContext } from "./AdvancedCompressionProvider";
import Phase1 from "./Phase1";
import Phase2 from "./Phase2";

export default function AdvancedCompression() {
  const { compression } = useContext(AdvancedCompressionContext);
  return (
    <div>
      <Phase1 />
      {compression.compression_id && <Phase2 />}
    </div>
  );
}
