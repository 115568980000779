import ArticleIcon from "@mui/icons-material/Article";
import CompressIcon from "@mui/icons-material/Compress";
import GitHubIcon from "@mui/icons-material/GitHub";
import LaunchIcon from "@mui/icons-material/Launch";
import StorageIcon from "@mui/icons-material/Storage";
import LanguageIcon from "@mui/icons-material/Language";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as NetsPressoModelCompressorLogo } from "../../assets/svgs/NetsPresso-MC-color.svg";

const SideBar = () => {
  const portalUrl = `${process.env.REACT_APP_PORTAL_URL}`;
  const searcherUrl = `${process.env.REACT_APP_SEARCHER_URL}`;
  return (
    <div className="font-['Gilroy'] w-[186px] min-w-[186px] flex flex-col bg-[#0D163A] text-white">
      {/* 로고 */}
      <Link
        to="models"
        className="w-full p-4 flex items-center justify-center no-underline hover:no-underline"
      >
        <NetsPressoModelCompressorLogo />
      </Link>
      {/* 네비게이션 */}
      <div className="px-2 py-2 flex-1 flex flex-col font-semibold">
        <div className="flex flex-col gap-2">
          <NavLink
            to="models"
            className={({ isActive }) =>
              `px-2 py-1 flex text-white rounded-sm no-underline transition-all
              hover:no-underline hover:text-white hover:bg-[#125aaf] hover:cursor-pointer ${
                isActive ? "bg-[#125aaf]" : ""
              }`
            }
          >
            <div className="mr-2 flex items-center justify-center">
              <StorageIcon />
            </div>
            Models
          </NavLink>
          <NavLink
            to="compression"
            className={({ isActive }) =>
              `px-2 py-1 flex text-white rounded-sm no-underline transition-all
              hover:no-underline hover:text-white hover:bg-[#125aaf] hover:cursor-pointer ${
                isActive ? "bg-[#125aaf]" : ""
              }`
            }
          >
            <div className="mr-2 flex items-center justify-center">
              <CompressIcon />
            </div>
            Compress
          </NavLink>
          <hr className="mb-2 mx-2 border-gray-500" />
        </div>
        <div className="flex-1">
          <a
            href={searcherUrl}
            className="px-2 py-1 flex text-white rounded-sm no-underline transition-all hover:no-underline hover:text-white hover:bg-[#125aaf] hover:cursor-pointer"
          >
            <div className="mr-2 flex items-center justify-center">
              <LaunchIcon fontSize="small" />
            </div>
            NetsPresso
          </a>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          <hr className="mx-2 border-gray-500" />
          <a
            href={portalUrl}
            className="px-2 py-1 flex text-white rounded-sm no-underline transition-all hover:no-underline hover:text-white hover:bg-[#125aaf] hover:cursor-pointer"
          >
            <div className="mr-2 flex items-center justify-center">
              <LanguageIcon fontSize="small" />
            </div>
            Portal
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.netspresso.ai/docs/technology-support-1"
            className="px-2 py-1 flex text-white rounded-sm no-underline transition-all hover:no-underline hover:text-white hover:bg-[#125aaf] hover:cursor-pointer"
          >
            <div className="mr-2 flex items-center justify-center">
              <ArticleIcon fontSize="small" />
            </div>
            Documentation
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/Nota-NetsPresso/Discussion/discussions"
            className="px-2 py-1 flex text-white rounded-sm no-underline transition-all hover:no-underline hover:text-white hover:bg-[#125aaf] hover:cursor-pointer"
          >
            <div className="mr-2 flex items-center justify-center">
              <GitHubIcon fontSize="small" />
            </div>
            Discussion Forum
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
