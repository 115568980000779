import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

const QuestionTooltip = ({ content }) => {
  return (
    <Tooltip arrow title={<div className="text-base">{content}</div>}>
      <div className="text-base flex items-center justify-center">
        <HelpOutlineIcon fontSize="inherit" />
      </div>
    </Tooltip>
  );
};

export default QuestionTooltip;
