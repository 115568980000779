import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Collapse } from "@mui/material";
import { useContext } from "react";
import Paper from "src/components/Paper";
import Title from "src/components/Title";
import Button from "../../../components/Button";
import NetworkGraph from "../../../components/NetworkGraph";
import NetworkGraphTable from "../../../components/NetworkGraphTable";
import Spinner from "../../../components/Spinner";
import RecommendationModal from "../modals/RecommendationModal";
import ResultModal from "../modals/ResultModal";
import { AdvancedCompressionContext } from "./AdvancedCompressionProvider";

export default function Phase2() {
  const {
    show,
    input,
    models,
    model,
    compression,
    layers,
    compressedModel,
    GetModels,
    PostCompression,
    PostDataset,
    PostRecommendation,
    PutCompression,
    GetCompressedModel,
    GetUser,
    handleChangeInput,
    handleClickToggleShow,
    handleChangeDatasetFile,
    handleClickResetAll,
    handleClickSelectMethod,
    handleClickClearInputs,
    handleClickModify,
    handleClickCompress,
    handleClickCheckLayer,
    handleClickCheckLayerAll,
    handleChangeTableInput,
    handleClickCancel,
  } = useContext(AdvancedCompressionContext);
  return (
    <Paper>
      <div
        className="p-3 flex items-center justify-between cursor-pointer"
        data-name="phase2"
        onClick={handleClickToggleShow}
      >
        <Title>2. Set configuration & compress model</Title>
        <div>{show.phase2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
      </div>
      <Collapse orientation="vertical" in={show.phase2}>
        <div className="p-3">
          <div className="flex justify-between mb-3 gap-3">
            <div className="flex items-center justify-center">
              {/* {["PR_L2", "PR_GM", "PR_NN"].includes(
                compression.compression_method
              ) && (
                <div>
                  <LabelTitle>Policy</LabelTitle>
                  <div className="flex gap-2">
                    <label className="mb-0 flex items-center gap-1">
                      <input
                        type="radio"
                        value="average"
                        name="policy"
                        checked={input.policy === "average"}
                        onChange={handleChangeInput}
                        disabled={
                          PutCompression.isLoading || PutCompression.isSuccess
                        }
                      />
                      Average
                      <QuestionTooltip
                        content={tooltip.compress_config_l2norm_policy_average}
                      />
                    </label>
                  </div>
                </div>
              )} */}
            </div>
            <div className="flex items-center justify-end gap-2">
              {(PutCompression.isLoading || PostCompression.isLoading) && (
                <div className="flex items-center justify-center gap-2">
                  <div>This process can take up to 5 minutes.</div>
                  <Spinner />
                </div>
              )}
              {["FD_TK", "FD_SVD", "PR_L2", "PR_GM", "PR_NN"].includes(
                compression.compression_method
              ) && (
                <div>
                  <Button
                    disabled={
                      PutCompression.isLoading || PutCompression.isSuccess
                    }
                    data-name="recommendationModal"
                    onClick={handleClickToggleShow}
                  >
                    Recommendation
                  </Button>
                  {show.recommendationModal && <RecommendationModal />}
                </div>
              )}
              <div>
                <Button
                  onClick={handleClickCompress}
                  disabled={
                    PutCompression.isLoading || PutCompression.isSuccess
                  }
                >
                  Compress
                </Button>
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              style={{
                minWidth: "300px",
                flex: 1,
                height: "calc(100vh - 250px)",
                border: "solid 1px #d8dbe0",
              }}
            >
              <NetworkGraph
                model={model}
                compression={compression}
                layers={layers}
                handleClickCheckLayer={handleClickCheckLayer}
                disabled={PutCompression.isLoading || PutCompression.isSuccess}
              />
            </div>
            <div
              style={{
                height: "calc(100vh - 250px)",
                overflow: "auto",
                borderTop: "solid 1px #d8dbe0",
                borderBottom: "solid 1px #d8dbe0",
              }}
              id="table-container"
            >
              <NetworkGraphTable
                model={model}
                compression={compression}
                layers={layers}
                handleClickCheckLayer={handleClickCheckLayer}
                handleClickCheckLayerAll={handleClickCheckLayerAll}
                handleChangeTableInput={handleChangeTableInput}
                disabled={PutCompression.isLoading || PutCompression.isSuccess}
              />
            </div>
          </div>
          <div className="flex items-center justify-end mt-3 gap-2">
            {(PutCompression.isLoading || PostCompression.isLoading) && (
              <div className="flex items-center justify-center gap-2">
                <div>This process can take up to 5 minutes.</div>
                <Spinner />
              </div>
            )}
            <Button
              color="red"
              onClick={handleClickCancel}
              disabled={PutCompression.isLoading}
            >
              Cancel
            </Button>
            <Button
              color="red"
              onClick={handleClickResetAll}
              disabled={PutCompression.isLoading}
            >
              Reset
            </Button>
            {!PutCompression.isSuccess && (
              <Button
                color="red"
                onClick={handleClickClearInputs}
                disabled={PutCompression.isLoading}
              >
                Clear Inputs
              </Button>
            )}
            {PutCompression.isSuccess && (
              <Button
                onClick={handleClickModify}
                disabled={PutCompression.isLoading}
              >
                Modify
              </Button>
            )}
            {!PutCompression.isSuccess && (
              <Button
                onClick={handleClickCompress}
                disabled={PutCompression.isLoading}
              >
                Compress
              </Button>
            )}
            {PutCompression.isSuccess && GetCompressedModel.isSuccess && (
              <div>
                <Button data-name="resultModal" onClick={handleClickToggleShow}>
                  Show Result
                </Button>
                {show.resultModal && (
                  <ResultModal
                    show={show}
                    beforeModel={model}
                    afterModel={compressedModel}
                    handleClickToggleShow={handleClickToggleShow}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </Paper>
  );
}
