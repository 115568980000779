import { memo } from "react";

const Button = ({ children, disabled, onClick, "data-cy": dataCy }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className="w-72 mt-4 px-4 py-2 text-lg text-white border border-white rounded-md active:bg-gray-100/50 hover:bg-gray-100/50 disabled:text-gray-400 disabled:border-gray-300 disabled:hover:bg-gray-100/0 flex justify-center"
      data-cy={dataCy}
    >
      {disabled ? "Loading..." : children}
    </button>
  );
};

export default memo(Button);
