import { observable } from "mobx";

const control = observable({
  selectedLayer: "",
  setSelectedLayer(layer) {
    this.selectedLayer = layer;
  },
});

export { control };
