import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SearchIcon from "@mui/icons-material/Search";
import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import IconButton from "src/components/IconButton";
import Pagenation from "src/components/Pagenation";
import TextInput from "src/components/TextInput";
import { getParentsModels } from "src/library/apis";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import TableRow from "./TableRow";

const ModelsPage = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState("");
  const [queryText, setQueryText] = useState("");
  const GetParentsModels = useQuery(["parentsModels"], getParentsModels, {
    onSuccess: ({ data }) => {},
    onError: () => {},
  });
  const sortedModels = useMemo(() => {
    if (GetParentsModels.isSuccess) {
      return GetParentsModels.data.data.sort(
        (modelA, modelB) => modelA.created_time - modelB.created_time
      );
    } else {
      return [];
    }
  }, [GetParentsModels.isSuccess, GetParentsModels?.data?.data]);
  const queriedModel = useMemo(() => {
    return sortedModels.filter((model) => model.model_name.includes(query));
  }, [query, sortedModels]);
  const pagedModels = useMemo(() => {
    return queriedModel.filter((model, index) => {
      return offset * limit <= index && index < (offset + 1) * limit;
    });
  }, [limit, offset, queriedModel]);
  const handleClickUploadModel = () => {
    navigate("/models/upload");
  };
  const handleChangeQueryText = (e) => {
    setQueryText(e.target.value);
  };
  const handlePressEnterSearch = (e) => {
    if (e.key === "Enter") {
      setQuery(queryText);
      setOffset(0);
    }
  };
  const handleClickSearch = () => {
    setQuery(queryText);
  };
  const handleClickPage = (offset) => {
    setOffset(offset);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between gap-2">
        <div>
          <Button
            onClick={handleClickUploadModel}
            data-cy="models-upload-button"
          >
            <DriveFolderUploadIcon sx={{ marginRight: 1 }} />
            Upload Model
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <TextInput
            placeholder="Search model name"
            value={queryText}
            onChange={handleChangeQueryText}
            onKeyPress={handlePressEnterSearch}
          />
          <IconButton variant="contained" onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
      <Paper>
        <table className="w-full table-auto">
          <thead>
            <tr className="">
              <th className="text-center w-[60px] max-w-[60px] min-w-[60px]"></th>
              <th className="text-left w-[400px]">Name</th>
              <th className="text-left">Task</th>
              <th className="text-left">Device</th>
              <th className="text-left">Evaluation</th>
              <th className="text-left">Latency (ms)</th>
              <th className="text-right"></th>
              <th className="text-center w-[60px] max-w-[60px] min-w-[60px]"></th>
            </tr>
          </thead>
          <tbody>
            {GetParentsModels.isLoading && (
              <tr>
                <td colSpan={8}>
                  <div className="h-20 flex items-center justify-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
            {GetParentsModels.isSuccess && pagedModels.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <div className="h-20 flex items-center justify-center gap-2">
                    <ErrorOutlineIcon />
                    {sortedModels.length === 0 && pagedModels.length === 0 && (
                      <div>Please upload models</div>
                    )}
                    {sortedModels.length > 0 && pagedModels.length === 0 && (
                      <div>No model exists for the current condition</div>
                    )}
                  </div>
                </td>
              </tr>
            )}
            {GetParentsModels.isSuccess &&
              pagedModels.map((model, index) => {
                return <TableRow key={model.model_id + index} model={model} />;
              })}
          </tbody>
        </table>
      </Paper>
      {GetParentsModels.isSuccess && (
        <Pagenation
          total={queriedModel.length}
          offset={offset}
          limit={limit}
          onClick={handleClickPage}
        />
      )}
    </div>
  );
};

export default observer(ModelsPage);
