import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { ReactComponent as AdvancedCompressionIcon } from "../../assets/svgs/AdvancedCompressionIcon.svg";
import { ReactComponent as AutomaticCompressionIcon } from "../../assets/svgs/AutomaticCompressionIcon.svg";

export default function CompressionModeSelectPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClickAutomaticCompression = () => {
    navigate({
      pathname: "/compression/automatic",
      search: location.search,
    });
  };
  const handleClickAdvancedCompression = () => {
    navigate({
      pathname: "/compression/advanced",
      search: location.search,
    });
  };
  return (
    <div className="w-full flex justify-center gap-4">
      <Card>
        <div className="w-[300px] p-8 flex flex-col items-center gap-4">
          <div className="w-[140px] h-[140px] rounded-full border-2 border-blue-800 flex flex-col justify-center items-center text-center text-blue-800">
            <div className="w-[48px] h-[48px] flex justify-center items-center">
              <AutomaticCompressionIcon />
            </div>
            Automatic
            <br />
            Compression
          </div>
          <div className="h-[200px] w-full">
            <ul className="list-disc">
              <li>One-click compression</li>
              <li>May not be the best results</li>
            </ul>
          </div>
          <Button onClick={handleClickAutomaticCompression}>Compress</Button>
        </div>
      </Card>
      <Card>
        <div className="w-[300px] p-8 flex flex-col items-center gap-4">
          <div className="w-[140px] h-[140px] rounded-full border-2 border-blue-800 flex flex-col justify-center items-center text-center text-blue-800">
            <div className="w-[48px] h-[48px] flex justify-center items-center">
              <AdvancedCompressionIcon />
            </div>
            Advanced
            <br />
            Compression
          </div>
          <div className="h-[200px] w-full">
            <ul className="list-disc">
              <li>Model Visualization</li>
              <li>Model Profiling on the target device</li>
              <li>Structured Pruning and Filter Decomposition</li>
              <li>Compress each layer to achieve the best results</li>
            </ul>
          </div>
          <Button onClick={handleClickAdvancedCompression}>Compress</Button>
        </div>
      </Card>
    </div>
  );
}
