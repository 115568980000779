import PropTypes from "prop-types";
import React from "react";

const IconButton = React.forwardRef((props, ref) => {
  return (
    <button
      {...props}
      className={`p-[2px] border rounded
        ${
          props.variant === "outlined"
            ? "text-blue-900 border-blue-900 disabled:text-gray-300 disabled:border-gray-300"
            : props.variant === "contained"
            ? "text-white bg-blue-900 border-blue-900 disabled:bg-gray-300 disabled:border-gray-300"
            : props.variant === "ghost"
            ? "text-blue-900 border-transparent disabled:text-gray-300"
            : ""
        }
        `}
    />
  );
});

IconButton.propTypes = {
  variant: PropTypes.oneOf(["outlined", "contained", "ghost"]),
};

IconButton.defaultProps = {
  variant: "outlined",
};

export default IconButton;
