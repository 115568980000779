import { useMemo } from "react";
import {
  useStore as useReactFlowStore,
  useZoomPanHelper,
} from "react-flow-renderer";
import Select from "src/components/Select";
import tooltip from "src/constants/tooltip";
import { getDeviceLabel } from "src/library/utils";
import useStore from "src/stores";
import LatencyGuage from "./LatencyGuage";
import QuestionTooltip from "./QuestionTooltip";

const method2prop = {
  FD_TK: ["In Channel", "Out Channel", "In Rank", "Out Rank"],
  FD_SVD: ["In Channel", "Out Channel", "Rank"],
  FD_CP: ["In Channel", "Out Channel", "Rank"],
  PR_ID: ["Out Channel", "Channels"],
  PR_L2: ["Out Channel", "Ratio"],
  PR_GM: ["Out Channel", "Ratio"],
  PR_NN: ["Out Channel", "Ratio"],
};
const NetworkGraphTable = ({
  model,
  compression,
  layers,
  handleClickCheckLayer,
  handleClickCheckLayerAll,
  handleChangeTableInput,
  disabled,
}) => {
  const { control } = useStore();
  const reactFlowStore = useReactFlowStore();
  const { setCenter } = useZoomPanHelper();
  const maxLatency = useMemo(() => {
    return compression.available_layers.reduce((maxLatency, currentLayer) => {
      const currentlatency = currentLayer.latency.find(
        (latency) => latency.device_name === model.target_device
      ).value;
      return maxLatency > currentlatency ? maxLatency : currentlatency;
    }, 0);
  }, [compression.available_layers, model.target_device]);
  const handleClickLayer = (e) => {
    const layerName = e.currentTarget.dataset.layerName;
    const { nodes } = reactFlowStore.getState();
    const node = nodes.find((node) => node.values.name === layerName);
    if (node) {
      const x = node.__rf.position.x + node.__rf.width / 2 + 150;
      const y = node.__rf.position.y + node.__rf.height / 2;
      const zoom = 1;
      setCenter(x, y, zoom);
      control.setSelectedLayer(node);
    }
  };
  const totalLatency = model.devices.find(
    (device) => device.name === model.target_device
  ).total_latency;
  return (
    <div className="w-fit">
      <table className="table text-sm">
        <thead>
          <tr>
            <th className="text-left">Latency</th>
            <td className="text-left">
              {totalLatency > 0
                ? `${totalLatency} ms`
                : totalLatency === 0
                ? "Measuring..."
                : totalLatency === -1
                ? "Error"
                : ""}
            </td>
            <th className="text-left">Device</th>
            <td className="text-left">
              <Select disabled>
                <option>{getDeviceLabel(model.target_device)}</option>
              </Select>
            </td>
          </tr>
          <tr className="">
            <th>
              <div className="flex items-center justify-center">
                <input
                  type="checkbox"
                  onChange={handleClickCheckLayerAll}
                  checked={
                    layers.filter((layer) => layer.use === true).length ===
                    layers.length
                  }
                  disabled={disabled}
                />
              </div>
            </th>
            <th>
              <div className="flex items-center whitespace-nowrap">
                Layer Name&nbsp;
                <QuestionTooltip content={tooltip.compress_config_layer_name} />
              </div>
            </th>
            {method2prop[compression.compression_method].map((property) => (
              <th key={property}>
                <div className="flex items-center whitespace-nowrap">
                  {property}&nbsp;
                  <QuestionTooltip
                    content={
                      tooltip[
                        `compress_config_${property
                          .toLowerCase()
                          .replace(" ", "_")}`
                      ]
                    }
                  />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {layers.map((layer) => (
            <tr
              key={layer.name}
              onClick={handleClickLayer}
              id={`table-layer-${layer.name}`}
              className=" hover:bg-gray-100"
              data-layer-name={layer.name}
            >
              <td>
                <div className="p-1 flex items-center justify-center">
                  <input
                    type="checkbox"
                    data-layer-name={layer.name}
                    checked={layer.use}
                    onChange={handleClickCheckLayer}
                    disabled={disabled}
                  />
                </div>
              </td>
              <td>
                <div className="p-1">
                  <div>
                    <b>{`${layer.name}`}</b>
                  </div>
                  <LatencyGuage
                    latency={
                      layer.latency.find(
                        (e) => e.device_name === model.target_device
                      ).value
                    }
                    max={maxLatency}
                  />
                </div>
              </td>
              {method2prop[compression.compression_method].includes(
                "In Channel"
              ) && (
                <td>
                  <div className="p-1">{layer.channels[0]}</div>
                </td>
              )}
              {method2prop[compression.compression_method].includes(
                "Out Channel"
              ) && (
                <td>
                  <div className="p-1">
                    {layer.channels[layer.channels.length - 1]}
                  </div>
                </td>
              )}
              {method2prop[compression.compression_method].includes("Rank") && (
                <td>
                  <div className="p-1 flex items-center whitespace-nowrap text-xs">
                    {"0 <"}
                    <input
                      id={`${layer.name}-${"rank"}`}
                      disabled={layer.use === false || disabled}
                      value={layer.values[0]}
                      data-layer-name={layer.name}
                      data-column-name={"rank"}
                      onChange={handleChangeTableInput}
                      className="text-center w-12 m-1 min-w-[3rem] border text-sm focus:outline-none"
                    />
                    {"≤ " + Math.min(layer.channels[0], layer.channels[1])}
                  </div>
                </td>
              )}
              {method2prop[compression.compression_method].includes(
                "In Rank"
              ) && (
                <td>
                  <div className="p-1 flex items-center whitespace-nowrap text-xs">
                    {"0 <"}
                    <input
                      id={`${layer.name}-${"inRank"}`}
                      disabled={layer.use === false || disabled}
                      value={layer.values[0]}
                      data-layer-name={layer.name}
                      data-column-name={"inRank"}
                      onChange={handleChangeTableInput}
                      className="text-center w-12 m-1 min-w-[3rem] border text-sm focus:outline-none"
                    />
                    {"≤ " + layer.channels[0]}
                  </div>
                </td>
              )}
              {method2prop[compression.compression_method].includes(
                "Out Rank"
              ) && (
                <td>
                  <div className="p-1 flex items-center whitespace-nowrap text-xs">
                    {"0 <"}
                    <input
                      id={`${layer.name}-${"outRank"}`}
                      disabled={layer.use === false || disabled}
                      value={layer.values[1]}
                      data-layer-name={layer.name}
                      data-column-name={"outRank"}
                      onChange={handleChangeTableInput}
                      className="text-center w-12 m-1 min-w-[3rem] border text-sm focus:outline-none"
                    />
                    {"≤ " + layer.channels[1]}
                  </div>
                </td>
              )}
              {method2prop[compression.compression_method].includes(
                "Channels"
              ) && (
                <td>
                  <div className="p-1 flex items-center whitespace-nowrap text-xs">
                    {"0 <"}
                    <input
                      id={`${layer.name}-${"channels"}`}
                      disabled={layer.use === false || disabled}
                      value={layer.text}
                      data-layer-name={layer.name}
                      data-column-name={"channels"}
                      onChange={handleChangeTableInput}
                      placeholder={"e.g. 1-5, 8, 11-13"}
                      className="text-center w-12 m-1 min-w-[3rem] border text-sm focus:outline-none"
                    />
                    {"< " + layer.channels[0]}
                  </div>
                </td>
              )}
              {method2prop[compression.compression_method].includes(
                "Ratio"
              ) && (
                <td>
                  <div className="p-1 flex items-center whitespace-nowrap text-xs">
                    {"0.0 <"}
                    <input
                      id={`${layer.name}-${"ratio"}`}
                      disabled={layer.use === false || disabled}
                      value={layer.values[0]}
                      data-layer-name={layer.name}
                      data-column-name={"ratio"}
                      onChange={handleChangeTableInput}
                      className="text-center w-12 m-1 min-w-[3rem] border text-sm focus:outline-none"
                    />
                    {"≤ 1.0"}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NetworkGraphTable;
