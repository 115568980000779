import FirstPageIcon from "@mui/icons-material/FirstPage";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  ControlButton,
  Controls,
  isNode,
  useStore as useReactFlowStore,
  useZoomPanHelper,
} from "react-flow-renderer";
import { getLayoutedElements } from "src/library/utils";
import useStore from "src/stores";
import NetworkGraphNode from "./NetworkGraphNode";
import NetworkGraphNodeDetail from "./NetworkGraphNodeDetail";

const NetworkGraph = ({
  model,
  compression,
  layers,
  disabled,
  handleClickCheckLayer,
}) => {
  const store = useReactFlowStore();
  const { setCenter } = useZoomPanHelper();
  const [graphInstanceReady, setGraphInstanceReady] = useState(false);
  const [layoutReady, setLayoutReady] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState();
  const { control } = useStore();

  useEffect(() => {
    setSelectedLayer(control.selectedLayer);
  }, [control.selectedLayer]);

  const maxLatency = useMemo(() => {
    return compression.available_layers.reduce((maxLatency, currentLayer) => {
      const currentlatency = currentLayer.latency.find(
        (latency) => latency.device_name === model.target_device
      ).value;
      return maxLatency > currentlatency ? maxLatency : currentlatency;
    }, 0);
  }, [compression.available_layers, model.target_device]);

  const [elements, setElements] = useState([]);

  useEffect(() => {
    const setLayoutedElements = async () => {
      const layoutedElements = await getLayoutedElements(
        model,
        compression.available_layers
      );
      const customElements = layoutedElements.map((element) => {
        if (isNode(element)) {
          return {
            ...element,
            data: {
              label: (
                <NetworkGraphNode
                  model={model}
                  layers={layers}
                  element={element}
                  setSelectedLayer={setSelectedLayer}
                  handleClickCheckLayer={handleClickCheckLayer}
                  maxLatency={maxLatency}
                  disabled={disabled}
                />
              ),
            },
            style: {
              padding: 0,
              border: "None",
              width: 200,
            },
          };
        } else {
          return {
            ...element,
            animated: true,
          };
        }
      });
      setElements(customElements);
      setLayoutReady(true);
    };
    setLayoutedElements();
  }, [
    compression.available_layers,
    disabled,
    handleClickCheckLayer,
    layers,
    maxLatency,
    model,
  ]);

  const goFirst = useCallback(() => {
    const { nodes } = store.getState();
    if (nodes.length) {
      const node = nodes[0];
      const x = node.__rf.position.x + node.__rf.width / 2;
      const y = node.__rf.position.y + node.__rf.height / 2;
      const zoom = 1;
      setCenter(x, y, zoom);
    }
  }, [setCenter, store]);

  const handleLoad = useCallback((reactFlowInstance) => {
    setGraphInstanceReady(true);
  }, []);

  useEffect(() => {
    if (graphInstanceReady && layoutReady) {
      goFirst();
    }
  }, [goFirst, graphInstanceReady, layoutReady]);

  const handleGoFirstClick = useCallback(() => {
    goFirst();
  }, [goFirst]);

  const handleElementClick = useCallback(() => {}, []);

  const handlePaneClick = useCallback(() => {
    setSelectedLayer();
  }, []);

  const handleMoveStart = useCallback(() => {
    // setSelectedLayer();
  }, []);

  return (
    <div className="w-full h-full">
      <ReactFlow
        elements={elements}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={false}
        zoomOnScroll={false}
        panOnScroll={true}
        panOnScrollMode={"free"}
        paneMoveable={true}
        snapToGrid={true}
        onLoad={handleLoad}
        onElementClick={handleElementClick}
        onPaneClick={handlePaneClick}
        onMoveStart={handleMoveStart}
      >
        <Controls showFitView={false} showInteractive={false}>
          <ControlButton onClick={handleGoFirstClick}>
            <FirstPageIcon />
          </ControlButton>
        </Controls>
        {selectedLayer && (
          <NetworkGraphNodeDetail
            selectedLayer={selectedLayer}
            setSelectedLayer={setSelectedLayer}
          />
        )}
      </ReactFlow>
    </div>
  );
};

export default observer(NetworkGraph);
