import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./layout/Dashboard";
import CompressionModeSelectPage from "./pages/Compression";
import AdvancedCompressionPage from "./pages/Compression/Advanced";
import AutomaticCompressionPage from "./pages/Compression/Automatic";
import LoginPage from "./pages/Login";
import ModelsPage from "./pages/Models";
import ModelDetailPage from "./pages/Models/Detail";
import ModelUploadPage from "./pages/Models/Upload";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Navigate to="/models" replace />} />
          <Route path="models" element={<ModelsPage />} />
          <Route path="models/upload" element={<ModelUploadPage />} />
          <Route path="models/:modelId" element={<ModelDetailPage />} />
          <Route path="compression" element={<CompressionModeSelectPage />} />
          <Route
            path="compression/automatic"
            element={<AutomaticCompressionPage />}
          />
          <Route
            path="compression/advanced"
            element={<AdvancedCompressionPage />}
          />
          <Route path="*" element={<Navigate to="/models" replace />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
