import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getModel,
  getParentsModels,
  getUser,
  postCompression,
  postDataset,
  postRecommendation,
  putCompression,
} from "src/library/apis";
import {
  getFileFromEvent,
  getFileNameExtension,
  getIndexListFromString,
} from "src/library/utils";

const getRecommendedLayers = (recommendationLayers, layers) => {
  const newLayers = layers.map((layer) => {
    const recommendationLayer = recommendationLayers.find(
      (recommendationLayer) => recommendationLayer.name === layer.name
    );
    if (!!recommendationLayer) {
      return {
        ...layer,
        use: true,
        values: [...recommendationLayer.values],
      };
    } else {
      return {
        ...layer,
        use: false,
      };
    }
  });
  return newLayers;
};

export const AdvancedCompressionContext = createContext();

const initialShow = {
  phase1: true,
  phase2: false,
  recommendationModal: false,
  resultModal: false,
};

const initialInput = {
  modelName: "",
  description: "",
  modelId: "",
  compressionMethod: "",
  datasetFile: "",
  policy: "average",
  recommendationMethod: "",
  ratio: 0,
};

export default function AdvancedCompressionProvider({ children }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(initialShow);
  const [input, setInput] = useState(initialInput);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState({});
  const [compression, setCompression] = useState({});
  const [layers, setLayers] = useState([]);
  const [compressedModel, setCompressedModel] = useState({});

  const queryClient = useQueryClient();
  const GetModels = useQuery(["parentsModels"], getParentsModels, {
    refetchOnMount: true,
    onSuccess: ({ data: models }) => {
      // models 설정
      setModels(models);
      // 쿼리스트링에 설정된 모델이 있는 경우 해당 model로 설정
      const modelIdFromQueryString = searchParams.get("model");
      if (modelIdFromQueryString) {
        const model = models.find(
          (model) => model.model_id === modelIdFromQueryString
        );
        if (model) {
          setModel(model);
          setInput({ ...input, modelId: modelIdFromQueryString });
        } else {
          alert("Invalid model id");
        }
      }
    },
  });
  const PostCompression = useMutation(postCompression, {
    onSuccess: ({ data: compression }) => {
      // layers 설정
      setLayers(compression.available_layers);
      // compression 설정
      setCompression(compression);
    },
  });
  const PostDataset = useMutation(postDataset);
  const PostRecommendation = useMutation(postRecommendation, {
    onSuccess: ({ data: recommendation_layers }) => {
      setLayers(getRecommendedLayers(recommendation_layers, layers));
      setShow({
        ...show,
        recommendationModal: false,
      });
    },
  });
  const PutCompression = useMutation(putCompression, {
    onSuccess: ({ data: compression }) => {
      setCompression(compression);
      setLayers(compression.available_layers);
      queryClient.resetQueries(["compressedModel"]);
      queryClient.resetQueries(["user"]);
    },
  });
  const GetCompressedModel = useQuery(
    ["compressedModel", compression.new_model_id],
    () => getModel({ modelId: compression.new_model_id }),
    {
      enabled: !!compression.new_model_id,
      onSuccess: ({ data: compressedModel }) => {
        setCompressedModel(compressedModel);
        setShow({ ...show, resultModal: true });
      },
    }
  );
  const GetUser = useQuery(["user"], getUser);

  // 입력 변경시
  const handleChangeInput = (e) => {
    let value = e.target.value;
    if (e.target.name === "ratio" && value instanceof String) {
      value = value.replace(/[^0-9.]/g, "");
    }
    setInput({
      ...input,
      [e.target.name]: value,
    });
  };

  // 모델 선택 변경시
  const handleChangeBaseModel = (e) => {
    const modelId = e.target.value;
    setInput({ ...input, modelId: modelId, compressionMethod: "" });
    const model = models.find((model) => model.model_id === modelId);
    if (model) {
      setModel(model);
    } else {
      setModel({});
    }
  };

  // compression method 변경시
  const handleChangeCompressionMethod = (e) => {
    const compressionMethod = e.target.value;
    if (["FD_TK", "FD_SVD"].includes(compressionMethod)) {
      setInput({
        ...input,
        compressionMethod: compressionMethod,
        recommendationMethod: "vbmf",
        ratio: 0,
      });
    } else if (["PR_L2", "PR_GM", "PR_NN"].includes(compressionMethod)) {
      setInput({
        ...input,
        compressionMethod: compressionMethod,
        recommendationMethod: "lamp",
        ratio: 0.5,
      });
    } else {
      setInput({
        ...input,
        compressionMethod: compressionMethod,
      });
    }
  };

  // 토글시
  const handleClickToggleShow = (e) => {
    const name = e.currentTarget.dataset.name;
    setShow({ ...show, [name]: !show[name] });
  };

  // 데이터셋 파일 선택시
  const handleChangeDatasetFile = (e) => {
    const file = getFileFromEvent(e);
    if (file) {
      if (getFileNameExtension(file.name) !== "npy") {
        alert("Please select a valid dataset file(.npy)");
      } else {
        setInput({ ...input, datasetFile: file });
      }
    }
  };

  // reset all 선택시
  const handleClickResetAll = (e) => {
    setShow(initialShow);
    setInput(initialInput);
    setModels([]);
    setModel({});
    setCompression({});
    setLayers([]);
    setCompressedModel({});
    GetModels.remove();
    PostCompression.reset();
    PostDataset.reset();
    PostRecommendation.reset();
    PutCompression.reset();
    GetCompressedModel.remove();
    GetUser.remove();
  };

  // phase1 완료시
  const handleClickSelectMethod = async () => {
    const checkPhase1Validation = () => {
      if (input.modelName === "") {
        alert("Please enter a model name");
        return false;
      } else if (!input.modelName.match(/^[0-9a-zA-Z_]+$/)) {
        alert(
          "Only alphabets, numbers, and underscores can be entered in the model name."
        );
        return false;
      } else if (input.modelId === "") {
        alert("Please select a model");
        return false;
      } else if (input.compressionMethod === "") {
        alert("Please select a compression method");
        return false;
      } else if (
        input.compressionMethod === "PR_NN" &&
        input.datasetFile === ""
      ) {
        alert("Please select a dataset file");
        return false;
      } else if (
        input.compressionMethod === "PR_NN" &&
        input.datasetFile !== "" &&
        getFileNameExtension(input.datasetFile.name) !== "npy"
      ) {
        alert("Please select a valid dataset file");
        return false;
      }
      return true;
    };
    if (!checkPhase1Validation()) return;
    try {
      const requests = [
        PostCompression.mutateAsync({
          modelId: input.modelId,
          modelName: input.modelName,
          description: input.description,
          compressionMethod: input.compressionMethod,
        }),
        ...(input.compressionMethod === "PR_NN"
          ? [
              PostDataset.mutateAsync({
                modelId: input.modelId,
                file: input.datasetFile,
              }),
            ]
          : []),
      ];
      const responses = await Promise.all(requests);

      setShow({ ...show, phase1: false, phase2: true });
    } catch (error) {}
  };

  // 입력 초기화시
  const handleClickClearInputs = () => {
    setLayers(
      layers.map((layer) => {
        return {
          ...layer,
          values: [...layer.values].fill(""),
          use: false,
        };
      })
    );
  };

  // 수정버튼 클릭시
  const handleClickModify = async () => {
    const savedLayers = [...layers];
    const response = await PostCompression.mutateAsync({
      modelId: input.modelId,
      modelName: input.modelName,
      description: input.description,
      compressionMethod: input.compressionMethod,
    });
    setCompression(response.data);
    PutCompression.reset();
    setLayers(savedLayers);
  };

  // compress 버튼 클릭시
  const handleClickCompress = () => {
    const checkTableValidation = () => {
      // not used layer check
      const usedLayers = layers.filter((layer) => layer.use);
      if (usedLayers.length === 0) {
        alert("Please select at least one layer to proceed with compression");
        return false;
      }
      // used but empty value layer check
      const usedButEmptyValueLayers = layers.filter((layer) => {
        if (layer.use === true && layer.values.includes("")) return true;
        return false;
      });
      if (usedButEmptyValueLayers.length >= 1) {
        alert("Please enter a value for the selected layer");
        return false;
      }
      // used but invalid value layer check
      for (const layer of usedLayers) {
        if (["FD_TK"].includes(compression.compression_method)) {
          if (!(0 < layer.values[0] && layer.values[0] <= layer.channels[0])) {
            alert(`Please insert valid in rank at ${layer.name}`);
            document.getElementById(`${layer.name}-${"inRank"}`).focus();
            return false;
          }
          if (!(0 < layer.values[1] && layer.values[1] <= layer.channels[1])) {
            alert(`Please insert valid out rank at ${layer.name}`);
            document.getElementById(`${layer.name}-${"outRank"}`).focus();
            return false;
          }
        } else if (
          ["FD_SVD", "FD_CP"].includes(compression.compression_method)
        ) {
          const fullRank = Math.min(layer.channels[0], layer.channels[1]);
          if (!(0 < layer.values[0] && layer.values[0] <= fullRank)) {
            alert(`Please insert valid rank at ${layer.name}`);
            document.getElementById(`${layer.name}-${"rank"}`).focus();
            return false;
          }
        } else if (["PR_ID"].includes(compression.compression_method)) {
          const regexp =
            /^(([0-9]{1}[0-9]{0,}){1}(-[0-9]{1}[0-9]{0,}){0,1}){1}(,([0-9]{1}[0-9]{0,}){1}(-[0-9]{1}[0-9]{0,}){0,1}){0,}$/;
          if (!layer.text.match(regexp)) {
            alert(`Please insert valid channels at ${layer.name}`);
            document.getElementById(`${layer.name}-${"channels"}`).focus();
            return false;
          }
          const indexList = getIndexListFromString(layer.text);
          for (const index of indexList) {
            if (!(index >= 0 && index < layer.channels[0])) {
              alert(`Please insert valid channels at ${layer.name}`);
              document.getElementById(`${layer.name}-${"channels"}`).focus();
              return false;
            }
          }
        } else if (
          ["PR_L2", "PR_GM", "PR_NN"].includes(compression.compression_method)
        ) {
          if (
            !(0 < layer.values[0] && layer.values[0] <= 1) ||
            layer.values[0] === ""
          ) {
            alert(`Please insert valid ratio at ${layer.name}`);
            document.getElementById(`${layer.name}-${"ratio"}`).focus();
            return false;
          }
        }
      }
      return true;
    };
    if (
      !window.confirm(
        "Do you want to compress?\n50 credits will be used.\nCompression may take about 5 minutes."
      )
    )
      return;
    if (!checkTableValidation()) return false;
    PutCompression.mutate({
      compressionId: compression.compression_id,
      layers: layers,
      options: {
        policy: input.policy,
      },
    });
  };

  // recommendation 버튼 클릭시
  const handleClickRecommendation = () => {
    const checkRecommendationValidation = () => {
      if (input.recommendationMethod === "") {
        alert("Please select a recommendation method");
        return false;
      } else if (input.ratio === "") {
        alert("Please enter a pruning ratio");
        return false;
      } else if (input.compressionMethod === "lamp") {
        if (input.ratio <= 0 || input.ratio > 1) {
          alert("Please insert a valid pruning ratio");
          return false;
        }
      } else if (input.compressionMethod === "vbmf") {
        if (input.ratio < -1 || input.ratio > 1) {
          alert("Please insert a valid caribration ratio");
          return false;
        }
      }
      return true;
    };
    if (!checkRecommendationValidation) return;
    PostRecommendation.mutate({
      modelId: model.model_id,
      compressionId: compression.compression_id,
      recommendationMethod: input.recommendationMethod,
      recommendationRatio: input.ratio,
    });
  };

  // 레이어 체크 선택시
  const handleClickCheckLayer = (e) => {
    const layerName = e.target.dataset.layerName;
    const use = e.target.checked;
    setLayers(
      layers.map((layer) => {
        if (layer.name === layerName) {
          return {
            ...layer,
            use: use,
          };
        } else {
          return {
            ...layer,
          };
        }
      })
    );
  };

  // 모든 레이어 선택시
  const handleClickCheckLayerAll = (e) => {
    setLayers(
      layers.map((layer) => {
        return {
          ...layer,
          use: e.target.checked,
        };
      })
    );
  };

  // 테이블에서 입력 변경시
  const handleChangeTableInput = (e) => {
    const getFilteredString = (columnName, value) => {
      if (["rank", "inRank", "outRank"].includes(columnName)) {
        return value.replace(/[^0-9]/g, "");
      } else if (["channels"].includes(columnName)) {
        return value.replace(/[^0-9,-]/g, "");
      } else if (["ratio"].includes(columnName)) {
        return value.replace(/[^0-9.]/g, "");
      } else {
        return "";
      }
    };
    const getNewLayers = (layers, layerName, columnName, value) => {
      return layers.map((layer) => {
        if (layer.name === layerName) {
          if (["outRank"].includes(columnName)) {
            return { ...layer, values: [layer.values[0], value] };
          } else if (["channels"].includes(columnName)) {
            return {
              ...layer,
              values: getIndexListFromString(value),
              text: value,
            };
          } else {
            return { ...layer, values: [value] };
          }
        } else {
          return { ...layer };
        }
      });
    };
    const value = getFilteredString(
      e.target.dataset.columnName,
      e.target.value
    );
    const newLayers = getNewLayers(
      layers,
      e.target.dataset.layerName,
      e.target.dataset.columnName,
      value
    );
    setLayers(newLayers);
  };

  // 취소 버튼 선택시
  const handleClickCancel = () => {
    navigate(-1);
  };

  const value = {
    show,
    input,
    models,
    model,
    compression,
    layers,
    compressedModel,
    GetModels,
    PostCompression,
    PostDataset,
    PostRecommendation,
    PutCompression,
    GetCompressedModel,
    GetUser,
    handleChangeInput,
    handleChangeBaseModel,
    handleChangeCompressionMethod,
    handleClickToggleShow,
    handleChangeDatasetFile,
    handleClickResetAll,
    handleClickSelectMethod,
    handleClickClearInputs,
    handleClickModify,
    handleClickCompress,
    handleClickRecommendation,
    handleClickCheckLayer,
    handleClickCheckLayerAll,
    handleChangeTableInput,
    handleClickCancel,
  };
  return (
    <AdvancedCompressionContext.Provider value={value}>
      {children}
    </AdvancedCompressionContext.Provider>
  );
}
