import AdvancedCompression from "./AdvancedCompression";
import AdvancedCompressionProvider from "./AdvancedCompressionProvider";

export default function AdvancedCompressionPage() {
  return (
    <AdvancedCompressionProvider>
      <AdvancedCompression />
    </AdvancedCompressionProvider>
  );
}
