import React from "react";

const TextInput = React.forwardRef((props, ref) => {
  return (
    <div
      className={`w-full ${
        props.disabled && "bg-gray-200"
      } border border-gray-300 rounded flex items-center`}
    >
      {props.icon && <div className="p-2 text-gray-400">{props.icon}</div>}
      <input {...props} ref={ref} className="w-full p-2 text-black rounded" />
    </div>
  );
});

export default TextInput;
