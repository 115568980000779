import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react";
import useStore from "src/stores";
import Button from "../components/Button";

const CreditMessage = () => {
  const { user, modal } = useStore();
  const handleClose = () => {
    modal.setCreditMessageModalShow(false);
  };
  return (
    <Dialog open={modal.creditMessageModalShow} onClose={handleClose}>
      <DialogTitle>
        <div className="flex justify-between">
          <div>About Credit</div>
          <button onClick={handleClose} className="focus:outline-none">
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        {user.credit <= 30 && user.credit !== 0 ? (
          <Alert severity="warning">
            You currently only have {user.credit} credits.
          </Alert>
        ) : user.credit === 0 ? (
          <Alert severity="error">You have consumed all your credits.</Alert>
        ) : null}
        <div className="text-lg font-bold">What is credit?</div>
        <div>
          Credit is an electronic currency that can be used for compression,
          recommendation, and other features within Nota Model Compressor.
        </div>
        <br />
        <table className="table w-full">
          <thead>
            <tr className="text-left">
              <th>
                <div className="">Task</div>
              </th>
              <th>
                <div className="">Credit</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-left">
              <td>
                <div className="">Compression</div>
              </td>
              <td>
                <div className="">10</div>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="text-lg font-bold">Do you need more credit?</div>
        <p>
          Thank you for using our Model Compressor!
          <br />
          Get <b>500 credits</b> after giving us feedback to improve NPTK!
          <br />
          This is a limited opportunity for trial users.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          color="gray"
          onClick={() => modal.setCreditMessageModalShow(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => window.open("https://notaai.typeform.com/to/opHTvdom")}
        >
          Feedback
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(CreditMessage);
