import { memo } from "react";

const IconInput = (props) => {
  const {
    icon,
    type,
    name,
    placeholder,
    disabled,
    onChange,
    onKeyPress,
    "data-cy": dataCy,
  } = props;
  return (
    <div className="h-14 my-2 relative">
      <div className="h-14 w-14 absolute flex items-center justify-center text-black">
        {icon}
      </div>
      <input
        onChange={onChange}
        onKeyPress={onKeyPress}
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className="pl-14 w-full h-full text-lg bg-white rounded-md disabled:bg-gray-400"
        data-cy={dataCy}
      />
    </div>
  );
};

export default memo(IconInput);
