import { useState } from "react";
import Tab from "src/components/Tab";
import ModelInfo from "./ModelInfo";

const tabs = [{ label: "Model Info", value: "modelInfo" }];

export default function ModelDetailPage() {
  const [tab, setTab] = useState("modelInfo");
  const handleChangeTab = (e) => {
    setTab(e.target.value);
  };
  return (
    <div>
      <Tab tabs={tabs} value={tab} onChange={handleChangeTab} />
      {tab === "modelInfo" && <ModelInfo />}
    </div>
  );
}
