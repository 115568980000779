import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { postPassword } from "src/library/apis";
import Button from "./component/Button";
import IconInput from "./component/IconInput";
import Title from "./component/Title";

const NewPassword = ({ changeSite }) => {
  const [newPasswordInput, setNewPasswordInput] = useState({
    email: "",
    loading: false,
  });
  const handleChangeInput = (e) => {
    setNewPasswordInput({
      ...newPasswordInput,
      [e.target.name]: e.target.value,
    });
  };
  const checkInputValue = (input) => {
    // 이메일 주소 입력 점검
    if (input.email === "") {
      alert("Please enter a email address.");
      return false;
    }
    return true;
  };
  const handleClickNewPassword = async () => {
    if (!checkInputValue(newPasswordInput)) {
      return;
    }
    try {
      setNewPasswordInput({ ...newPasswordInput, loading: false });
      await postPassword({
        email: newPasswordInput.email,
      });
      setNewPasswordInput({ ...newPasswordInput, loading: false });
      alert("Done!\nPlease check your mail to get new password.");
      changeSite("login");
    } catch (error) {
      setNewPasswordInput({ ...newPasswordInput, loading: false });
      if (error.response) {
        if (error.response.status === 400) {
          alert("An invalid email has been entered.");
        }
      }
    }
  };
  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      handleClickNewPassword();
    }
  };
  const handleClickBack = () => {
    changeSite("login");
  };
  return (
    <div>
      <div>
        <button onClick={handleClickBack} className="text-white">
          <ArrowBackIcon /> Back to Login
        </button>
      </div>
      <br />
      <Title
        title="New Password"
        subtitle={
          <div>
            Please enter the email you signed up for.
            <br />
            We will send you a mail to set new password.
          </div>
        }
      >
        New Password
      </Title>
      <IconInput
        icon={<AlternateEmailIcon />}
        type="email"
        name="email"
        placeholder="Email"
        disabled={newPasswordInput.loading}
        onChange={handleChangeInput}
        onKeyPress={handlePressEnter}
      />
      <div className="flex justify-center">
        <Button
          disabled={newPasswordInput.loading}
          onClick={handleClickNewPassword}
        >
          Mail to get new password
        </Button>
      </div>
    </div>
  );
};

export default NewPassword;
